import Cookies from 'js-cookie';
import Router from 'next/router';

class AuthHelper {
  constructor() {
    const oneDay = 1;

    this.tokenName = 'token';
    this.cookieExpires = oneDay;
    this.cookiePath = '/';
  }

  signIn(token) {
    Cookies.set(this.tokenName, token, {
      expires: this.cookieExpires,
      path: this.cookiePath,
    });
  }

  signOut() {
    Cookies.remove(this.tokenName, { path: this.cookiePath });
    Router.push('/sign-in');
  }

  getToken() {
    return Cookies.get(this.tokenName);
  }

  isAuthenticated() {
    return !!this.getToken();
  }
}

export default new AuthHelper();
