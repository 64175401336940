import { combineReducers } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';

import filtersReducer from '@redux/shared/reducers/filters.reducer';
import createMetadataReducer from '@redux/shared/reducers/createMetadata.reducer';

import {
  FREIGHT_CAPACITIES_FETCHED,
  FREIGHT_CAPACITY_FETCHED,
  FREIGHT_CAPACITY_CREATED,
  FREIGHT_CAPACITY_CLAIMS_FETCHED,
  FREIGHT_CAPACITY_IMPRESSIONS_FETCHED,
  FREIGHT_CAPACITY_REJECTIONS_FETCHED,
  FREIGHT_CAPACITY_VIEWS_FETCHED,
} from './actions';

const idsReducer = (state = [], action) => {
  switch (action.type) {
    case FREIGHT_CAPACITIES_FETCHED:
      return get(action, 'payload.result', []);
    case FREIGHT_CAPACITY_CREATED:
      return [...state, get(action, 'payload.id')];
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_CAPACITIES_FETCHED:
      return get(action, 'payload.entities.freightCapacity', {});
    case FREIGHT_CAPACITY_CREATED:
      return merge({}, state, { [get(action, 'payload').id]: get(action, 'payload') });
    case FREIGHT_CAPACITY_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightCapacity'));
    default:
      return state;
  }
};

const claimsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_CAPACITY_CLAIMS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightCapacityClaim'));
    default:
      return state;
  }
};

const impressionsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_CAPACITY_IMPRESSIONS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightCapacityImpression'));
    default:
      return state;
  }
};

const rejectionsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_CAPACITY_REJECTIONS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightCapacityRejection'));
    default:
      return state;
  }
};

const viewsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_CAPACITY_VIEWS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightCapacityView'));
    default:
      return state;
  }
};

const reducer = combineReducers({
  byId: byIdReducer,
  ids: idsReducer,
  capacityClaimsById: claimsByIdReducer,
  capacityImpressionsById: impressionsByIdReducer,
  capacityRejectionsById: rejectionsByIdReducer,
  capacityViewsById: viewsByIdReducer,
  filters: filtersReducer('freightCapacitiesFilters'),
  metadata: createMetadataReducer('freightCapacitiesMetadata'),
});

export default reducer;
