import { PLACES_FETCHED } from '../places/places.actions';
import { PLACE_DETAILS_FETCHED } from '../placeDetails/placeDetails.actions';
import { allIds as placeAllIds, placesData } from '../places/places.reducer';
import { allIds as detailAllIds, placeDetailsData } from '../placeDetails/placeDetails.reducer';

import {
  MASS_EDIT_PLACE_DETAILS_FETCHED,
  MASS_EDIT_PLACES_FETCHED,
  CLEAR_MASS_EDIT_STATE,
  SET_MASS_EDIT_LOCATION_NAME,
} from './massEdit.actions';

const initMassEditDataIdsState = {
  placesIds: [],
  placeDetailsIds: [],
};

export const massEditDataIds = (state = initMassEditDataIdsState, action) => {
  switch (action.type) {
    case MASS_EDIT_PLACES_FETCHED: {
      return {
        ...state,
        placesIds: placeAllIds({}, { type: PLACES_FETCHED, payload: action.payload }),
      };
    }
    case MASS_EDIT_PLACE_DETAILS_FETCHED: {
      return {
        ...state,
        placeDetailsIds: detailAllIds({}, { type: PLACE_DETAILS_FETCHED, payload: action.payload }),
      };
    }
    case CLEAR_MASS_EDIT_STATE:
      return initMassEditDataIdsState;
    default:
      return state;
  }
};

const initMassEditDataState = {
  places: {},
  placeDetails: {},
};

export const massEditData = (state = initMassEditDataState, action) => {
  switch (action.type) {
    case MASS_EDIT_PLACES_FETCHED: {
      return {
        ...state,
        places: placesData({}, { type: PLACES_FETCHED, payload: action.payload }),
      };
    }
    case MASS_EDIT_PLACE_DETAILS_FETCHED: {
      return {
        ...state,
        placeDetails: placeDetailsData(
          {},
          { type: PLACE_DETAILS_FETCHED, payload: action.payload }
        ),
      };
    }
    case CLEAR_MASS_EDIT_STATE:
      return initMassEditDataState;
    default:
      return state;
  }
};

export const locationName = (state = '', action) => {
  switch (action.type) {
    case SET_MASS_EDIT_LOCATION_NAME: {
      return action.locationName;
    }
    default:
      return state;
  }
};
