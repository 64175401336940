import { normalize } from 'normalizr';
import { combineReducers } from 'redux';
import omitBy from 'lodash/omitBy';

import {
  PLACE_META_TYPES_FETCHED,
  PLACE_META_TYPE_REMOVE,
  PLACE_META_TYPE_ADD,
  PLACE_META_TYPE_UPDATE,
  EXTEND_PLACE_META_TYPE,
} from '@redux/placeMetaTypes/placeMetaTypes.actions';
import { placeMetaTypesSchema } from '@shared/schemas/placeMetaTypes';
import filtersReducer from '@redux/shared/reducers/filters.reducer';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import placeMetaTypeReducer from './placeMetaType.reducer';

const allIds = (state = [], action) => {
  switch (action.type) {
    case PLACE_META_TYPES_FETCHED:
      return action.payload.result;
    case PLACE_META_TYPE_ADD:
      return [...state, action.payload.result];
    case PLACE_META_TYPE_REMOVE:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

const placeMetaTypesData = (state = {}, action) => {
  switch (action.type) {
    case PLACE_META_TYPES_FETCHED: {
      const placeMetaTypes = action.payload.result.map(id =>
        placeMetaTypeReducer(
          {},
          {
            type: EXTEND_PLACE_META_TYPE,
            payload: action.payload.entities.placeMetaTypes[id],
            placeMetaTypes: action.payload.entities.placeMetaTypes,
          }
        )
      );
      return normalize(placeMetaTypes, placeMetaTypesSchema).entities.placeMetaTypes;
    }
    case PLACE_META_TYPE_ADD:
    case PLACE_META_TYPE_UPDATE:
      return {
        ...state,
        [action.id]: placeMetaTypeReducer(
          {},
          {
            type: EXTEND_PLACE_META_TYPE,
            payload: action.payload.entities.placeMetaTypes[action.id],
            placeMetaTypes: state,
          }
        ),
      };
    case PLACE_META_TYPE_REMOVE:
      return omitBy(state, { id: action.payload });
    default:
      return state;
  }
};
// eslint-enable complexity
const placeMetaTypesReducer = combineReducers({
  byId: placeMetaTypesData,
  ids: allIds,
  metadata: createMetadataReducer('placeMetaTypes'),
  filters: filtersReducer('placeMetaTypes'),
});

export default placeMetaTypesReducer;
