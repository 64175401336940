import without from 'lodash/without';
import isArray from 'lodash/isArray';

import {
  TOGGLE_ALL_SELECTED_IDS,
  TOGGLE_SELECTED_ID,
  SET_ALL_IDS,
} from '@redux/shared/actions/selectIds.actions';

const initState = [];
/* eslint-disable complexity */
const selectedIdsReducer = node => (state = initState, action) => {
  if (action.storeNode !== node) return state;
  switch (action.type) {
    case TOGGLE_ALL_SELECTED_IDS: {
      const { ids } = action;
      return ids.length > state.length ? ids : [];
    }
    case TOGGLE_SELECTED_ID: {
      const { id } = action;
      return state.includes(id) ? without(state, id) : [id, ...state];
    }
    case SET_ALL_IDS: {
      const { ids } = action;
      return isArray(ids) ? ids : [];
    }
    default:
      return state;
  }
};
/* eslint-enable complexity */
export default selectedIdsReducer;
