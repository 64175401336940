import getTheme from '@shared/utils/getTheme';

// Import variables shared with bootstrap
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
const sharedColors = require('sass-extract-loader?{"plugins":["sass-extract-js"]}!./styles/scss/shared/colors.scss');

const shared = {
  colors: sharedColors,
};

export default getTheme(shared);
