import { combineReducers } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';

import filtersReducer from '@redux/shared/reducers/filters.reducer';
import createMetadataReducer from '@redux/shared/reducers/createMetadata.reducer';

import {
  EQUIPMENT_TYPES_FETCHED,
  EQUIPMENT_TYPE_ADDED,
  EQUIPMENT_TYPE_UPDATE,
} from './equipmentTypes.actions';

const equipmentTypesIdsReducer = (state = [], action) => {
  switch (action.type) {
    case EQUIPMENT_TYPES_FETCHED:
      return get(action, 'payload.result', []);
    case EQUIPMENT_TYPE_ADDED:
      return [...state, get(action, 'payload.result')];
    default:
      return state;
  }
};

const equipmentTypesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case EQUIPMENT_TYPES_FETCHED:
      return get(action, 'payload.entities.equipmentType', {});
    case EQUIPMENT_TYPE_ADDED:
    case EQUIPMENT_TYPE_UPDATE:
      return merge({}, state, get(action, 'payload.entities.equipmentType'));
    default:
      return state;
  }
};

const equipmentTypesReducer = combineReducers({
  byId: equipmentTypesByIdReducer,
  ids: equipmentTypesIdsReducer,
  filters: filtersReducer('equipmentTypesFilters'),
  metadata: createMetadataReducer('equipmentTypesMetadata'),
});

export default equipmentTypesReducer;
