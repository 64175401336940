export default class RoutesConsts {
  static HOME() {
    return {
      HOME: {
        path: '/',
        as: '/',
      },
    };
  }

  static PARTNERS(id) {
    return {
      PARTNERS: {
        name: 'Partners',
        path: '/partners',
        as: '/partners',
      },
      PARTNERS_LIST: {
        name: 'Partners List',
        path: '/partners',
        as: '/partners',
      },
      PARTNERS_FREIGHT_INTEGRATIONS: {
        name: 'Freight Integrations',
        path: '/partners/freight-integrations',
        as: '/partners/freight-integrations',
      },
      PARTNERS_ADD_FREIGHT_INTEGRATION: {
        name: 'Add Integration',
        path: '/partners/freight-integrations/add-freight-integration',
        as: '/partners/freight-integrations/add-freight-integration',
      },
      PARTNERS_EDIT_FREIGHT_INTEGRATION: {
        path: '/partners/freight-integrations/[id]',
        as: `/partners/freight-integrations/${id}`,
      },
    };
  }

  static FREIGHTS(id) {
    return {
      FREIGHT: {
        name: 'Freight',
        path: '/freight/shipments',
        as: '/freight/shipments',
      },
      FREIGHT_SHIPMENTS: {
        name: 'Shipments',
        path: '/freight/shipments',
        as: '/freight/shipments',
      },
      FREIGHT_SHIPMENTS_DETAILS: {
        path: '/freight/shipments/[id]',
        as: `/freight/shipments/${id}`,
      },
      FREIGHT_REQUESTS: {
        name: 'Requests',
        path: '/freight/requests',
        as: '/freight/requests',
      },
      FREIGHT_REQUESTS_DETAILS: {
        path: '/freight/requests/[id]',
        as: `/freight/requests/${id}`,
      },
      FREIGHT_CAPACITIES: {
        name: 'Capacities',
        path: '/freight/capacities',
        as: '/freight/capacities',
      },
      FREIGHT_CAPACITIES_DETAILS: {
        path: '/freight/capacities/[id]',
        as: `/freight/capacities/${id}`,
      },
      FREIGHT_ANALYTICS: {
        name: 'Analytics',
        path: '/freight/analytics',
        as: '/freight/analytics',
      },
    };
  }

  static DATA_TYPES() {
    return {
      DATA_TYPES: {
        name: 'Data Types',
        path: '/data-types/capacity',
        as: '/data-types/capacity',
      },
      DATA_TYPES_CAPACITY: {
        name: 'Capacity',
        path: '/data-types/capacity',
        as: '/data-types/capacity',
      },
      DATA_TYPES_FREIGHT_DISPLAYS: {
        name: 'Freight Displays',
        path: '/data-types/freight-displays',
        as: '/data-types/freight-displays',
      },
      DATA_TYPES_EQUIPMENT: {
        name: 'Equipment',
        path: '/data-types/equipment',
        as: '/data-types/equipment',
      },
      DATA_TYPES_SHIPMENT_REJECTIONS: {
        name: 'Shipment Rejection',
        path: '/data-types/shipment-rejections',
        as: '/data-types/shipment-rejections',
      },
    };
  }

  static PLACES(id) {
    return {
      PLACES: {
        name: 'Places',
        path: '/places',
        as: '/places',
      },
      PLACE_DETAIL: {
        name: 'Place detail',
        path: '/places/[id]/details',
        as: `/places/${id}/details`,
      },
      PLACE_LIST: {
        name: 'Places List',
        path: '/places',
        as: '/places',
      },
      EDIT_PLACE_INFO: {
        path: '/places/[id]/place-info',
        as: `/places/${id}/place-info`,
      },
      PLACE_INFO: {
        name: 'Add Place Info',
        path: '/places/add-place-info',
        as: '/places/add-place-info',
      },
      PLACE_TYPES: {
        name: 'Place Types',
        path: '/places/place-types',
        as: '/places/place-types',
      },
      PLACE_META_TYPES: {
        name: 'Place Meta Types',
        path: '/places/place-meta-types',
        as: '/places/place-meta-types',
      },
      PLACE_MASS_EDIT: {
        name: 'Place Mass Edit',
        path: '/places/mass-edit',
        as: '/places/mass-edit',
      },
      PLACE_ANNOTATION_TYPES: {
        name: 'Annotation Types',
        path: '/places/annotation-types',
        as: '/places/annotation-types',
      },
      ANNOTATIONS: {
        name: 'Annotations',
        path: '/places/annotations',
        as: '/places/annotations',
      },
      ANNOTATION_EDIT: {
        as: `/places/${id}/annotation`,
        path: '/places/[id]/annotation',
      },
      REVIEWS_STATS: {
        as: `/places/${id}/review-stats`,
        path: '/places/[id]/review-stats',
      },
      REVIEWS: {
        as: `/places/${id}/reviews`,
        path: '/places/[id]/reviews',
      },
    };
  }

  static REVIEWS(id) {
    return {
      REVIEWS: {
        name: 'Reviews',
        path: '/reviews',
        as: '/reviews',
      },
      REVIEW_DETAIL: {
        name: 'Review Details',
        path: '/reviews/[id]/details',
        as: `/reviews/${id}/details`,
      },
      REVIEW_ANNOTATIONS: {
        name: 'Review Annotations',
        path: '/reviews/[id]/annotations',
        as: `/reviews/${id}/annotations`,
      },
      REVIEW_FEEDBACK: {
        name: 'Review Feedback',
        path: '/reviews/[id]/feedback',
        as: `/reviews/${id}/feedback`,
      },
      ALL_REVIEWS: {
        name: 'All Reviews',
        path: '/reviews',
        as: '/reviews',
      },
      REVIEWS_FEEDBACK: {
        name: 'Reviews Feedback',
        path: '/reviews-feedback',
        as: '/reviews-feedback',
      },
      SECTION_TYPES: {
        name: 'Section Types',
        path: '/reviews-section-types',
        as: '/reviews-section-types',
      },
      SECTION_TYPE: {
        path: '/reviews-section-types/[id]/section-type',
        as: `/reviews-section-types/${id}/section-type`,
      },
      ITEM_TYPES: {
        name: 'Item Types',
        path: '/reviews-item-types',
        as: '/reviews-item-types',
      },
    };
  }

  static TRUCK_ROUTES() {
    return {
      TRUCK_ROUTES: {
        name: 'Truck Routes',
        path: '/truck-routes',
        as: '/truck-routes',
      },
    };
  }

  static COMPANIES() {
    return {
      COMPANY: {
        name: 'Companies',
        path: '/companies',
        as: '/companies',
      },
      COMPANIES: {
        name: 'Companies',
        path: '/companies',
        as: '/companies',
      },
    };
  }

  static SCRAPPERS(id) {
    return {
      SCRAPPERS: {
        name: 'Scrapers',
        path: '/scrapers',
        as: '/scrapers',
      },
      SCRAPPERS_LOG: {
        path: '/scraperLogs/[id]',
        as: `/scraperLogs/${id}`,
      },
      SCRAPPERS_LIST: {
        name: 'Scrapers List',
        path: '/scrapers',
        as: '/scrapers',
      },
      CRONS: {
        name: 'Crons',
        path: '/scraper-crons',
        as: '/scraper-crons',
      },
    };
  }

  static FEEDBACK(id) {
    return {
      FEEDBACK: {
        name: 'Feedback',
        path: '/feedback',
        as: '/feedback',
      },
      FEEDBACK_DETAIL: {
        path: '/feedback/[id]',
        as: `/feedback/${id}`,
      },
    };
  }

  static USERS(id) {
    return {
      USERS: {
        name: 'Users',
        path: '/users',
        as: '/users',
      },
      USERS_EDIT: {
        path: '/user/[id]/edit',
        as: `/user/${id}/edit`,
      },
      USER_REVIEW: {
        path: '/user/[id]/reviews',
        as: `/user/${id}/reviews`,
      },
    };
  }

  static REPORTS(id) {
    return {
      REPORTS: {
        name: 'Reports',
        path: '/reports',
        as: '/reports',
      },
      REPORT_CREATE: {
        name: 'Create report',
        path: '/reports/create',
        as: '/reports/create',
      },
      REPORT_DETAIL: {
        name: 'View/Edit details',
        path: '/reports/[id]/details',
        as: `/reports/${id}/details`,
      },
      REPORT_RESULTS: {
        name: id,
        path: '/reports/[id]/results',
        as: `/reports/${id}/results`,
      },
    };
  }
}
