import { combineReducers } from 'redux';

import { ACTIVE, ARCHIVED } from '@shared/consts/reportsConsts';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';
import filtersReducer from '../shared/reducers/filters.reducer';

import reportData from './reportData.reducer';
import {
  REPORTS_FETCHED,
  REPORT_RESULTS_FETCHED,
  REPORTS_TOTALS_UPDATED,
  QUERY_VALIDATED,
  SET_NAME_AVAILABLE,
} from './reports.actions';

const allIds = (state = [], action) => {
  switch (action.type) {
    case REPORTS_FETCHED:
      return action.payload.result || [];
    default:
      return state;
  }
};

const reportsData = (state = [], action) => {
  switch (action.type) {
    case REPORTS_FETCHED:
      return action.payload.entities.reports || [];
    default:
      return state;
  }
};

const reportResults = (state = [], action) => {
  switch (action.type) {
    case REPORT_RESULTS_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const totals = (state = { [ACTIVE]: null, [ARCHIVED]: null }, action) => {
  switch (action.type) {
    case REPORTS_TOTALS_UPDATED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const queryValidated = (state = false, action) => {
  switch (action.type) {
    case QUERY_VALIDATED:
      return action.payload;
    default:
      return state;
  }
};

const nameAvailable = (state = null, action) => {
  switch (action.type) {
    case SET_NAME_AVAILABLE:
      return action.payload;
    default:
      return state;
  }
};

const initialFilters = {
  status: ACTIVE,
  last_run_order: 'desc',
};

const reportsReducer = combineReducers({
  byId: reportsData,
  ids: allIds,
  totals,
  queryValidated,
  nameAvailable,
  reportData,
  reportResults,
  filters: filtersReducer('reportsFilters', initialFilters),
  metadata: createMetadataReducer('reports'),
});

export default reportsReducer;
