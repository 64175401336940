import React from 'react';
import styled from 'styled-components';

import truckMapLogoImage from '@images/truckmap-logo.svg';

const Image = styled.img`
  height: 3rem;
`;

const TruckMapLogo = props => <Image src={truckMapLogoImage} {...props} />;

export default TruckMapLogo;
