import { combineReducers } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';

import filtersReducer from '@redux/shared/reducers/filters.reducer';
import createMetadataReducer from '@redux/shared/reducers/createMetadata.reducer';

import {
  FREIGHT_INTEGRATIONS_FETCHED,
  FREIGHT_INTEGRATION_FETCHED,
  FREIGHT_INTEGRATION_ADDED,
  FREIGHT_INTEGRATION_UPDATED,
} from './actions';

const idsReducer = (state = [], action) => {
  switch (action.type) {
    case FREIGHT_INTEGRATIONS_FETCHED:
      return get(action, 'payload.result', []);
    case FREIGHT_INTEGRATION_ADDED:
      return [...state, get(action, 'payload.result')];
    case FREIGHT_INTEGRATION_FETCHED:
      return uniq([...state, get(action, 'payload.result')]);
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_INTEGRATIONS_FETCHED:
      return get(action, 'payload.entities.freightIntegration', {});
    case FREIGHT_INTEGRATION_ADDED:
    case FREIGHT_INTEGRATION_UPDATED:
      return merge({}, state, get(action, 'payload.entities.freightIntegration'));
    case FREIGHT_INTEGRATION_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightIntegration'));
    default:
      return state;
  }
};

const freightIntegrationsReducer = combineReducers({
  byId: byIdReducer,
  ids: idsReducer,
  filters: filtersReducer('freightIntegrationsFilters'),
  metadata: createMetadataReducer('freightIntegrationsMetadata'),
});

export default freightIntegrationsReducer;
