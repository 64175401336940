import uniqid from 'uniqid';

import {
  MASS_EDIT_ADD_PLACE_DETAIL,
  MASS_EDIT_UPDATE_PLACE_DETAIL,
  MASS_EDIT_DELETE_PLACE_DETAIL,
  CLEAR_MASS_EDIT_STATE,
} from './massEdit.actions';

const massEditChangeDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case MASS_EDIT_ADD_PLACE_DETAIL:
      return [
        ...state,
        {
          id: uniqid(),
          place_meta_type_id: null,
          place_detail_values: [],
        },
      ];
    case MASS_EDIT_DELETE_PLACE_DETAIL:
      return state.filter(detail => detail.id !== action.id);
    case MASS_EDIT_UPDATE_PLACE_DETAIL: {
      const { detail: detailToUpdate } = action;
      const newState = state.map(detail => {
        if (detail.id === detailToUpdate.id) return detailToUpdate;
        return detail;
      });
      return newState;
    }
    case CLEAR_MASS_EDIT_STATE:
      return [];
    default:
      return state;
  }
};
/* eslint-enable complexity */

export default massEditChangeDetailsReducer;
