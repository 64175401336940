export const NEXT_STEP = 'NEXT_STEP';
export const PREVIOUS_STEP = 'PREVIOUS_STEP';
export const UPDATED_PLACE_FILTERS = 'UPDATED_PLACE_FILTERS';
export const MASS_EDIT_PLACES_FETCHED = 'MASS_EDIT_PLACES_FETCHED';
export const MASS_EDIT_PLACE_DETAILS_FETCHED = 'MASS_EDIT_PLACE_DETAILS_FETCHED';
export const MASS_EDIT_SAVE_PLACE_DETAILS = 'MASS_EDIT_SAVE_PLACE_DETAILS';
export const MASS_EDIT_ADD_PLACE_DETAIL = 'MASS_EDIT_ADD_PLACE_DETAIL';
export const MASS_EDIT_UPDATE_PLACE_DETAIL = 'MASS_EDIT_UPDATE_PLACE_DETAIL';
export const MASS_EDIT_DELETE_PLACE_DETAIL = 'MASS_EDIT_DELETE_PLACE_DETAIL';
export const SET_MASS_EDIT_INIT_PLACES_TO_UPDATE = 'SET_MASS_EDIT_INIT_PLACES_TO_UPDATE';
export const CLEAR_MASS_EDIT_STATE = 'CLEAR_MASS_EDIT_STATE';
export const SET_MASS_EDIT_LOCATION_NAME = 'SET_MASS_EDIT_LOCATION_NAME';
