export const REPORTS_FETCHED = 'REPORTS_FETCHED';
export const REPORTS_TOTALS_UPDATED = 'REPORTS_TOTALS_UPDATED';

export const REPORT_FETCHED = 'REPORT_FETCHED';
export const REPORT_RESULTS_FETCHED = 'REPORT_RESULTS_FETCHED';

export const SET_NAME_AVAILABLE = 'SET_NAME_AVAILABLE';
export const QUERY_VALIDATED = 'QUERY_VALIDATED';
export const REPORT_VALUES_CHANGED = 'REPORT_VALUES_CHANGED';
export const NOTIFICATION_ELEMENT_ADD = 'NOTIFICATION_ELEMENT_ADD';
export const NOTIFICATION_VALUE_CHANGED = 'NOTIFICATION_VALUE_CHANGED';
export const NOTIFICATION_ELEMENT_REMOVED = 'NOTIFICATION_ELEMENT_REMOVED';
export const NOTIFICATION_ACTIVE_CHANGED = 'NOTIFICATION_ACTIVE_CHANGED';
export const INIT_REPORT_VALUES_SET = 'INIT_REPORT_VALUES_SET';
