import { combineReducers } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';

import createMetadataReducer from '@redux/shared/reducers/createMetadata.reducer';

import filtersReducer from '../shared/reducers/filters.reducer';

import {
  FREIGHT_SHIPMENTS_FETCHED,
  FREIGHT_SHIPMENT_FETCHED,
  FREIGHT_SHIPMENT_ADDED,
  FREIGHT_SHIPMENT_UPDATED,
  FREIGHT_SHIPMENT_IMPRESSIONS_FETCHED,
  FREIGHT_SHIPMENT_REJECTIONS_FETCHED,
  FREIGHT_SHIPMENT_CLAIMS_FETCHED,
  FREIGHT_SHIPMENT_VIEWS_FETCHED,
} from './freightShipments.actions';

const freightShipmentsIdsReducer = (state = [], action) => {
  switch (action.type) {
    case FREIGHT_SHIPMENTS_FETCHED:
      return get(action, 'payload.result', []);
    case FREIGHT_SHIPMENT_ADDED:
      return [...state, get(action, 'payload.result')];
    default:
      return state;
  }
};

const freightShipmentsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_SHIPMENTS_FETCHED:
      return get(action, 'payload.entities.freightShipment', {});
    case FREIGHT_SHIPMENT_ADDED:
    case FREIGHT_SHIPMENT_UPDATED:
    case FREIGHT_SHIPMENT_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightShipment'));
    default:
      return state;
  }
};

const freightShipmentImpressionsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_SHIPMENT_IMPRESSIONS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightShipmentImpression'));
    default:
      return state;
  }
};

const freightShipmentRejectionsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_SHIPMENT_REJECTIONS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightShipmentRejection'));
    default:
      return state;
  }
};

const freightShipmentClaimsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_SHIPMENT_CLAIMS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightShipmentClaim'));
    default:
      return state;
  }
};

const freightShipmentViewsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_SHIPMENT_VIEWS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightShipmentView'));
    default:
      return state;
  }
};

const freightShipmentsReducer = combineReducers({
  byId: freightShipmentsByIdReducer,
  ids: freightShipmentsIdsReducer,
  shipmentImpressionsById: freightShipmentImpressionsByIdReducer,
  shipmentRejectionsById: freightShipmentRejectionsByIdReducer,
  shipmentClaimsById: freightShipmentClaimsByIdReducer,
  shipmentViewsById: freightShipmentViewsByIdReducer,
  filters: filtersReducer('freightShipmentsFilters'),
  metadata: createMetadataReducer('freightShipmentsMetadata'),
});

export default freightShipmentsReducer;
