import { combineReducers } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import {
  REVIEW_SECTION_TYPES_FETCHED,
  REVIEW_SECTION_TYPE_FETCHED,
  REVIEW_SECTION_TYPE_UPDATE,
} from './reviewSectionTypes.actions';

const allIds = (state = [], action) => {
  switch (action.type) {
    case REVIEW_SECTION_TYPES_FETCHED:
    case REVIEW_SECTION_TYPE_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const sectionTypesData = (state = {}, action) => {
  switch (action.type) {
    case REVIEW_SECTION_TYPES_FETCHED:
    case REVIEW_SECTION_TYPE_FETCHED:
      return get(action, 'payload.entities.reviewSectionTypes', {});
    case REVIEW_SECTION_TYPE_UPDATE:
      return merge({}, state, get(action, 'payload.entities.reviewSectionTypes'));
    default:
      return state;
  }
};

const reviewSectionTypesReducer = combineReducers({
  byId: sectionTypesData,
  ids: allIds,
  metadata: createMetadataReducer('reviewSectionTypes'),
});

export default reviewSectionTypesReducer;
