import { combineReducers } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';

import filtersReducer from '@redux/shared/reducers/filters.reducer';
import createMetadataReducer from '@redux/shared/reducers/createMetadata.reducer';

import {
  FREIGHT_REQUESTS_FETCHED,
  FREIGHT_REQUEST_FETCHED,
  FREIGHT_REQUEST_LOCATIONS_FETCHED,
  FREIGHT_REQUEST_CAPACITIES_FETCHED,
  FREIGHT_REQUEST_DISPLAYS_FETCHED,
  FREIGHT_REQUEST_PARTNERS_FETCHED,
} from './actions';

const idsReducer = (state = [], action) => {
  switch (action.type) {
    case FREIGHT_REQUESTS_FETCHED:
      return get(action, 'payload.result', []);
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_REQUESTS_FETCHED:
      return get(action, 'payload.entities.freightRequest', {});
    case FREIGHT_REQUEST_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightRequest'));
    default:
      return state;
  }
};

const locationsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_REQUEST_LOCATIONS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightRequestLocation'));
    default:
      return state;
  }
};

const capacitiesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_REQUEST_CAPACITIES_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightRequestCapacity'));
    default:
      return state;
  }
};

const displaysByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_REQUEST_DISPLAYS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightRequestDisplay'));
    default:
      return state;
  }
};

const partnersByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FREIGHT_REQUEST_PARTNERS_FETCHED:
      return merge({}, state, get(action, 'payload.entities.freightRequestPartner'));
    default:
      return state;
  }
};

const reducer = combineReducers({
  byId: byIdReducer,
  ids: idsReducer,
  requestLocationsById: locationsByIdReducer,
  requestCapacitiesById: capacitiesByIdReducer,
  requestDisplaysById: displaysByIdReducer,
  requestPartnersById: partnersByIdReducer,
  filters: filtersReducer('freightRequestsFilters'),
  metadata: createMetadataReducer('freightRequestsMetadata'),
});

export default reducer;
