import { normalize } from 'normalizr';
import { combineReducers } from 'redux';
import omitBy from 'lodash/omitBy';

import { placeTypesSchema } from '@shared/schemas/placeTypes';
import filtersReducer from '@redux/shared/reducers/filters.reducer';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import placeTypeReducer from './placeType.reducer';
import {
  PLACE_TYPES_FETCHED,
  PLACE_TYPE_ADD,
  PLACE_TYPE_UPDATE,
  PLACE_TYPE_REMOVE,
  EXTEND_PLACE_TYPE,
} from './placeTypes.actions';

/* eslint-disable complexity */
const allIds = (state = [], action) => {
  switch (action.type) {
    case PLACE_TYPES_FETCHED:
      return action.payload.result;
    case PLACE_TYPE_ADD:
      return [...state, action.payload.result];
    case PLACE_TYPE_REMOVE:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

const placeTypesData = (state = {}, action) => {
  switch (action.type) {
    case PLACE_TYPES_FETCHED: {
      const placeTypes = action.payload.result.map(id =>
        placeTypeReducer(
          {},
          {
            type: EXTEND_PLACE_TYPE,
            payload: action.payload.entities.placeTypes[id],
          }
        )
      );
      return normalize(placeTypes, placeTypesSchema).entities.placeTypes;
    }
    case PLACE_TYPE_ADD:
    case PLACE_TYPE_UPDATE:
      return {
        ...state,
        [action.id]: placeTypeReducer(
          {},
          {
            type: EXTEND_PLACE_TYPE,
            payload: action.payload.entities.placeTypes[action.id],
          }
        ),
      };
    case PLACE_TYPE_REMOVE:
      return omitBy(state, { id: action.payload });
    default:
      return state;
  }
};
// eslint-enable complexity

const placeTypesReducer = combineReducers({
  byId: placeTypesData,
  ids: allIds,
  metadata: createMetadataReducer('placeTypes'),
  filters: filtersReducer('placeTypes'),
});

export default placeTypesReducer;
