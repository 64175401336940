import { combineReducers } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';

import {
  PARTNERS_FETCHED,
  PARTNER_ADDED,
  PARTNER_UPDATE,
  PARTNER_FETCHED,
  PARTNER_KEYS_FETCHED,
} from './partners.actions';

const partnersIdsReducer = (state = [], action) => {
  switch (action.type) {
    case PARTNERS_FETCHED:
      return get(action, 'payload.result', []);
    case PARTNER_ADDED:
      return [...state, get(action, 'payload.result')];
    default:
      return state;
  }
};

const partnersByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNERS_FETCHED:
      return get(action, 'payload.entities.partner', {});
    case PARTNER_ADDED:
    case PARTNER_UPDATE:
    case PARTNER_FETCHED:
      return merge({}, state, get(action, 'payload.entities.partner'));
    case PARTNER_KEYS_FETCHED:
      const newState = { ...state };
      return set(newState, [action.payload[0]?.partner_id, 'partner_keys'], action.payload);
    default:
      return state;
  }
};

const partnersReducer = combineReducers({
  byId: partnersByIdReducer,
  ids: partnersIdsReducer,
});

export default partnersReducer;
