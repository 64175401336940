import React from 'react';

import useAuth from '@shared/hooks/useAuth';
import SignInForm from '@shared/forms/SignInForm';
import withRedux from '@shared/utils/withRedux';
import AuthLayout from '@shared/layouts/AuthLayout';
import TruckMapLogo from '@shared/components/TruckMapLogo';
import Container from '@shared/components/Container';

const SignIn = () => {
  const { actions } = useAuth();

  const onGoogleSignIn = async data => {
    await actions.authorize({ token: data.tokenId });
  };

  const onGoogleSignInError = err => actions.authorizeHandler(err);

  return (
    <AuthLayout>
      <Container marginBottom="3rem">
        <TruckMapLogo />
      </Container>
      <SignInForm onGoogleSignIn={onGoogleSignIn} onGoogleSignInError={onGoogleSignInError} />
    </AuthLayout>
  );
};

export default withRedux(SignIn);
