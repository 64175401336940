import { combineReducers } from 'redux';
import get from 'lodash/get';

import filtersReducer from '@redux/shared/reducers/filters.reducer';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { ANNOTATIONS_TYPES_FETCHED } from './annotationTypes.actions';

const allIds = (state = [], action) => {
  switch (action.type) {
    case ANNOTATIONS_TYPES_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const companiesData = (state = {}, action) => {
  switch (action.type) {
    case ANNOTATIONS_TYPES_FETCHED:
      return get(action, 'payload.entities.annotationTypes', {});
    default:
      return state;
  }
};

const annotationTypesReducer = combineReducers({
  byId: companiesData,
  ids: allIds,
  metadata: createMetadataReducer('annotationTypes'),
  filters: filtersReducer('annotationTypes'),
});

export default annotationTypesReducer;
