import { SET_MASS_EDIT_INIT_PLACES_TO_UPDATE } from './massEdit.actions';

export const massEditInitPlacesToUpdate = (state = [], action) => {
  switch (action.type) {
    case SET_MASS_EDIT_INIT_PLACES_TO_UPDATE: {
      return action.places;
    }
    default:
      return state;
  }
};

export default massEditInitPlacesToUpdate;
