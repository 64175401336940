import { combineReducers } from 'redux';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import get from 'lodash/get';

import { PLACE_DETAILS_FETCHED } from '../placeDetails/placeDetails.actions';
import createMetadataReducer from '../shared/reducers/createMetadata.reducer';
import filtersReducer from '../shared/reducers/filters.reducer';

import { PLACES_FETCHED, PLACES_CLEAR } from './places.actions';
import {
  PLACE_FETCHED,
  UPDATE_PLACE,
  PLACE_CLEAR,
  UPDATE_PLACE_DETAILS_IDS,
  ADD_PLACE_DETAILS_IDS,
  PLACE_DETAIL_ID_REMOVE,
  PLACE_REVIEW_TIME_STATS_FETCHED,
  PLACE_REVIEW_VOTE_STATS_FETCHED,
} from './place.actions';
import place from './place.reducer';

export const allIds = (state = [], action) => {
  switch (action.type) {
    case PLACES_FETCHED:
      return action.payload.result;
    case PLACE_DETAILS_FETCHED:
      return [...state, action.id];
    case PLACE_CLEAR:
      return state.filter(id => id !== action.id);
    case PLACES_CLEAR:
      return [];
    default:
      return state;
  }
};

export const placesData = (state = {}, action) => {
  switch (action.type) {
    case PLACES_FETCHED: {
      return get(action, 'payload.entities.places', {});
    }

    case PLACE_FETCHED: {
      const placeId = action.payload.result;
      const currentPlace = state[placeId];
      const placeData = action.payload.entities.places[placeId];

      return {
        ...state,
        ...place(currentPlace, { type: UPDATE_PLACE, payload: placeData, id: placeId }),
      };
    }
    case PLACE_CLEAR:
      return omit(state, action.id);
    case PLACE_REVIEW_TIME_STATS_FETCHED: {
      const { placeId, payload } = action;
      return {
        ...state,
        [placeId]: {
          ...state[placeId],
          timeStatsCollection: payload,
        },
      };
    }
    case PLACE_REVIEW_VOTE_STATS_FETCHED: {
      const { placeId, payload } = action;
      return {
        ...state,
        [placeId]: {
          ...state[placeId],
          voteStatsCollection: payload,
        },
      };
    }
    case PLACES_CLEAR:
      return {};
    case ADD_PLACE_DETAILS_IDS: {
      const currentPlace = state[action.id];

      const getCurrentPlaceDetailsIds = () =>
        currentPlace && currentPlace.placeDetailsIds ? currentPlace.placeDetailsIds : [];
      const results = isArray(action.payload.result)
        ? action.payload.result
        : [action.payload.result];
      const placeData = {
        placeDetailsIds: uniq([...getCurrentPlaceDetailsIds(), ...results]),
      };

      return {
        ...state,
        ...place(currentPlace, { type: UPDATE_PLACE, payload: placeData, id: action.id }),
      };
    }
    case UPDATE_PLACE_DETAILS_IDS: {
      const currentPlace = state[action.id];
      const currentPlaceDetailsIds = currentPlace.placeDetailsIds.filter(
        item => item !== action.previousPlaceDetailId
      );

      return {
        ...state,
        ...place(currentPlace, {
          type: UPDATE_PLACE,
          payload: { placeDetailsIds: [...currentPlaceDetailsIds, action.payload.result] },
          id: action.id,
        }),
      };
    }
    case PLACE_DETAIL_ID_REMOVE: {
      const currentPlace = state[action.id];
      const placeData = {
        placeDetailsIds: currentPlace.placeDetailsIds.filter(
          placeDetailId => placeDetailId !== action.payload
        ),
      };

      return {
        ...state,
        ...place(currentPlace, { type: UPDATE_PLACE, payload: placeData, id: action.id }),
      };
    }
    default:
      return state;
  }
};
/* eslint-enable complexity */

const initialFilters = {
  includeCollections: ['company'],
};

const placesReducer = combineReducers({
  byId: placesData,
  ids: allIds,
  filters: filtersReducer('placeFilters', initialFilters),
  metadata: createMetadataReducer('places'),
});

export default placesReducer;
