import {
  SET_INITIALLY_FETCHED,
  SET_IS_LOADING,
  SET_TOTAL_COUNT,
} from '@redux/shared/actions/metadata.actions';

export const initialState = {
  initiallyFetched: false,
  isLoading: true,
  totalCount: 0,
};

/* eslint-disable complexity */
const createMetadataReducer = node =>
  function reducer(state = initialState, action) {
    if (action.storeNode !== node) return state;
    switch (action.type) {
      case SET_INITIALLY_FETCHED: {
        return { ...state, initiallyFetched: action.initiallyFetched };
      }

      case SET_IS_LOADING: {
        return { ...state, isLoading: action.isLoading };
      }

      case SET_TOTAL_COUNT: {
        return { ...state, totalCount: action.totalCount };
      }

      default:
        return state;
    }
  };
/* eslint-enable complexity */
export default createMetadataReducer;
