import { combineReducers } from 'redux';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { COMPANIES_FETCHED } from './companies.actions';


const allIds = (state = [], action) => {
  switch (action.type) {
    case COMPANIES_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const companiesData = (state = {}, action) => {
  switch (action.type) {
    case COMPANIES_FETCHED:
      return action.payload.entities.companies;
    default:
      return state;
  }
};

const companiesReducer = combineReducers({
  byId: companiesData,
  ids: allIds,
  metadata: createMetadataReducer('companies'),
});

export default companiesReducer;
