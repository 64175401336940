import { combineReducers } from 'redux';
import get from 'lodash/get';

import filtersReducer from '../shared/reducers/filters.reducer';
import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { REVIEW_ITEM_TYPE_FETCHED, REVIEW_ITEM_TYPES_FETCHED } from './reviewItemTypes.actions';

const allIds = (state = [], action) => {
  switch (action.type) {
    case REVIEW_ITEM_TYPE_FETCHED:
    case REVIEW_ITEM_TYPES_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const itemTypesData = (state = {}, action) => {
  switch (action.type) {
    case REVIEW_ITEM_TYPE_FETCHED:
    case REVIEW_ITEM_TYPES_FETCHED:
      return get(action, 'payload.entities.reviewItemTypes', {});
    default:
      return state;
  }
};

const reviewItemTypesReducer = combineReducers({
  byId: itemTypesData,
  ids: allIds,
  filters: filtersReducer('reviewItemTypes'),
  metadata: createMetadataReducer('reviewItemTypes'),
});

export default reviewItemTypesReducer;
