import {
  REVIEWS_UPDATE_PENDING_COUNT,
  REVIEWS_UPDATE_PENDING_COUNT_BY_PLACE,
  REVIEWS_UPDATE_PENDING_COUNT_BY_USER,
} from '@redux/reviews/reviews.actions';

const initState = {
  pending: 0,
  pendingByPlace: 0,
};

const reviewsCountReducer = (state = initState, action) => {
  switch (action.type) {
    case REVIEWS_UPDATE_PENDING_COUNT:
      return {
        ...state,
        pending: action.payload,
      };
    case REVIEWS_UPDATE_PENDING_COUNT_BY_PLACE:
      return {
        ...state,
        pendingByPlace: action.payload,
      };
    case REVIEWS_UPDATE_PENDING_COUNT_BY_USER:
      return {
        ...state,
        pendingByUser: action.payload,
      };
    default:
      return state;
  }
};

export default reviewsCountReducer;
