import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from '@shared/components/Container';
import Toaster from '@shared/components/Toaster';

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: ${props => props.theme.colors.lightGray};
`;

const Content = styled.div`
  max-width: 28.75rem;
`;

const AuthLayout = ({ children }) => (
  <StyledContainer>
    <Content>{children}</Content>
    <Toaster />
  </StyledContainer>
);

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default AuthLayout;
