import { combineReducers } from 'redux';
import get from 'lodash/get';

import filtersReducer from '@redux/shared/reducers/filters.reducer';
import createMetadataReducer from '@redux/shared/reducers/createMetadata.reducer';

import {
  FREIGHT_ANALYTICS_CAPACITY_MATCHES_FETCHED,
  FREIGHT_ANALYTICS_SHIPMENT_IMPRESSIONS_FETCHED,
  FREIGHT_ANALYTICS_SHIPMENT_REJECTIONS_FETCHED,
  FREIGHT_ANALYTICS_SHIPMENT_CLAIMS_FETCHED,
  FREIGHT_ANALYTICS_SHIPMENT_VIEWS_FETCHED,
} from './actions';

const freightAnalyticsReducer = (state = [], action) => {
  switch (action.type) {
    case FREIGHT_ANALYTICS_CAPACITY_MATCHES_FETCHED:
    case FREIGHT_ANALYTICS_SHIPMENT_IMPRESSIONS_FETCHED:
    case FREIGHT_ANALYTICS_SHIPMENT_CLAIMS_FETCHED:
    case FREIGHT_ANALYTICS_SHIPMENT_VIEWS_FETCHED:
    case FREIGHT_ANALYTICS_SHIPMENT_REJECTIONS_FETCHED:
      return get(action, 'payload.result', []);
    default:
      return state;
  }
};

const reducer = combineReducers({
  capacityMatches: freightAnalyticsReducer,
  capacityMatchesFilters: filtersReducer('freightAnalyticsCapacityMatchesFilters'),
  capacityMatchesMetadata: createMetadataReducer('freightAnalyticsCapacityMatchesMetadata'),

  shipmentImpressions: freightAnalyticsReducer,
  shipmentImpressionsFilters: filtersReducer('freightAnalyticsShipmentImpressionsFilters'),
  shipmentImpressionsMetadata: createMetadataReducer('freightAnalyticsShipmentImpressionsMetadata'),

  shipmentRejections: freightAnalyticsReducer,
  shipmentRejectionsFilters: filtersReducer('freightAnalyticsShipmentRejectionsFilters'),
  shipmentRejectionsMetadata: createMetadataReducer('freightAnalyticsShipmentRejectionsMetadata'),

  shipmentClaims: freightAnalyticsReducer,
  shipmentClaimsFilters: filtersReducer('freightAnalyticsShipmentClaimsFilters'),
  shipmentClaimsMetadata: createMetadataReducer('freightAnalyticsShipmentClaimsMetadata'),

  shipmentViews: freightAnalyticsReducer,
  shipmentViewsFilters: filtersReducer('freightAnalyticsShipmentViewsFilters'),
  shipmentViewsMetadata: createMetadataReducer('freightAnalyticsShipmentViewsMetadata'),
});

export default reducer;
