import { combineReducers } from 'redux';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { FEEDBACK_MANY_FETCHED } from './feedback.many.actions';
import { FEEDBACK_FETCHED, UPDATE_FEEDBACK } from './feedback.one.actions';
import feedback from './feedback.one.reducer';

const allIds = (state = [], action) => {
  switch (action.type) {
    case FEEDBACK_MANY_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const feedbackManyData = (state = {}, action) => {
  switch (action.type) {
    case FEEDBACK_MANY_FETCHED:
      return action.payload.entities.feedback;
    case FEEDBACK_FETCHED: {
      const feedbackId = action.payload.result;
      const currentFeedback = state[feedbackId];
      const feedbackData = action.payload.entities.feedback[feedbackId];
      return {
        ...state,
        ...feedback(currentFeedback, {
          type: UPDATE_FEEDBACK,
          payload: feedbackData,
          id: feedbackId,
        }),
      };
    }
    default:
      return state;
  }
};

const feedbackManyReducer = combineReducers({
  byId: feedbackManyData,
  ids: allIds,
  metadata: createMetadataReducer('feedback'),
});

export default feedbackManyReducer;
