import { combineReducers } from 'redux';
import get from 'lodash/get';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import {
  CRON_FREQUENCIES_FETCHED,
  ANNOTATION_CUSTOM_NAME_TYPES_FETCHED,
  REVIEW_ITEM_TYPES_INPUT_TYPES_FETCHED,
} from './dictionary.actions';

const frequencyIds = (state = [], action) => {
  switch (action.type) {
    case CRON_FREQUENCIES_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const frequencyData = (state = {}, action) => {
  switch (action.type) {
    case CRON_FREQUENCIES_FETCHED:
      return get(action, 'payload.entities.frequencies', {});
    default:
      return state;
  }
};

const customNameTypeData = (state = [], action) => {
  switch (action.type) {
    case ANNOTATION_CUSTOM_NAME_TYPES_FETCHED:
      return action.payload;
    default:
      return state;
  }
};

const reviewItemTypesInputTypesData = (state = [], action) => {
  switch (action.type) {
    case REVIEW_ITEM_TYPES_INPUT_TYPES_FETCHED:
      return action.payload;
    default:
      return state;
  }
};

const dictionaryReducer = combineReducers({
  frequencyIds,
  frequencyData,
  customNameTypeData,
  reviewItemTypesInputTypesData,
  frequencyMetadata: createMetadataReducer('frequencyMetadata'),
});

export default dictionaryReducer;
