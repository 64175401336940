import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { EXTEND_TASK_LOG } from './taskLog.actions';
import { EXTEND_TASK_AUDIT_LOG } from './auditLog.actions';
import { EXTEND_SCRAPER_LOG } from './scraperLog.actions';
import auditLog from './auditLog.reducer';
import scraperLog from './scraperLog.reducer';

const taskLog = (state = {}, action) => {
  const { children } = action.payload;
  if (isNil(children)) {
    return {
      ...action.payload,
      typeLabel: 'Task',
      hasContentToShow: true,
      hasEntries: action.payload.logs > 0,
      isMetaParams: !isEmpty(action.payload.params),
      isRestartAvailable: action.payload.status === 'REJECTED' || action.payload.status === 'ERROR',
    };
  }
  switch (action.type) {
    case EXTEND_TASK_LOG: {
      const localChildren = children.map(auditLogItem =>
        auditLog({}, { type: EXTEND_TASK_AUDIT_LOG, payload: auditLogItem })
      );
      const logState = {
        ...state,
        typeLabel: 'Task',
        children: localChildren,
        hasEntries: localChildren.some(log => log.hasEntries),
      };

      return scraperLog(logState, {
        type: EXTEND_SCRAPER_LOG,
        payload: action.payload,
      });
    }

    default:
      return state;
  }
};

/* eslint-enable complexity */

export default taskLog;
