class ApiError extends Error {
  constructor(error) {
    super(error);

    if (!error.response) return;

    this.name = 'ApiError';
    this.data = error.response.data;
    this.status = error.response.status;
    this.headers = error.response.headers;
  }
}

export default ApiError;
