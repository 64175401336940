import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';
import Card from 'react-bootstrap/Card';

import Button from '@shared/components/Button';

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};
`;

const Title = styled.h1`
  font-size: 5.2rem;
  color: ${props => props.theme.colors.danger};
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
`;

const Text = styled.p`
  margin-bottom: 1rem;
  font-weight: 300;
  text-align: center;
`;

const Refresh = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const Content = styled(Card)`
  max-width: 400px;
  margin: auto;
`;

const ButtonSendFeedback = styled(Button)`
  margin-top: 3rem;
  justify-content: center;
`;

const refresh = () => {
  window.location.reload();
};

const DefaultError = ({ sentryEventId, ...rest }) => {
  const onClickFeedback = () => Sentry.showReportDialog({ sentryEventId });
  return (
    <Container {...rest}>
      <Content>
        <Title>OOPS!</Title>
        <Text>
          Looks like something went wrong and the app crashed. This incident have been reported.
        </Text>
        <Refresh onClick={refresh}>Refresh page to continue your work</Refresh>
        <Text>We will be grateful if you leave your feedback about crash details</Text>
        <ButtonSendFeedback block onClick={onClickFeedback}>
          Report feedback
        </ButtonSendFeedback>
      </Content>
    </Container>
  );
};

DefaultError.propTypes = {
  sentryEventId: PropTypes.string.isRequired,
};

export default DefaultError;
