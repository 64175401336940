import omit from 'lodash/omit';

import {
  FILTERS_UPDATE,
  FILTERS_CLEAR,
  FILTERS_SET,
  FILTERS_CLEAR_FIELDS,
} from '@redux/shared/actions/filters.actions';

/* eslint-disable complexity */
const filtersReducer = (node, initialState = {}) => (state = initialState, action) => {
  if (action.storeNode !== node) return state;
  switch (action.type) {
    case FILTERS_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case FILTERS_SET:
      return {
        ...action.payload,
      };
    case FILTERS_CLEAR_FIELDS: {
      return omit(state, action.fieldKeys);
    }
    case FILTERS_CLEAR:
      return initialState;
    default:
      return state;
  }
};
/* eslint-enable complexity */
export default filtersReducer;
