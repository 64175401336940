import update from 'immutability-helper';

import { ACTIVE, INACTIVE } from '@shared/consts/reportsConsts';

import {
  REPORT_FETCHED,
  REPORT_VALUES_CHANGED,
  NOTIFICATION_ELEMENT_ADD,
  NOTIFICATION_VALUE_CHANGED,
  NOTIFICATION_ELEMENT_REMOVED,
  NOTIFICATION_ACTIVE_CHANGED,
  INIT_REPORT_VALUES_SET,
} from './reports.actions';

const initNotifications = [
  {
    type: 'email',
    targets: [{ value: '', valid: false }],
    status: 'inactive',
  },
  {
    type: 'slack',
    targets: [{ value: '', valid: false }],
    status: 'inactive',
  },
  {
    type: 'webhook',
    targets: [{ value: '', valid: false }],
    status: 'inactive',
  },
];

const initState = {
  id: null,
  status: null,
  values: {
    name: '',
    query: '',
    cron: '',
  },
  notifications: initNotifications,
  initReport: null,
};

const transformReportToState = fetchedReport => {
  const { id, status, name, cron, query, report_notifications } = fetchedReport;

  const notifications = [
    {
      type: 'email',
      targets: [{ value: '', valid: false }],
      status: 'inactive',
    },
    {
      type: 'slack',
      targets: [{ value: '', valid: false }],
      status: 'inactive',
    },
    {
      type: 'webhook',
      targets: [{ value: '', valid: false }],
      status: 'inactive',
    },
  ];

  report_notifications.forEach(element => {
    const index = initNotifications.findIndex(el => el.type === element.type);

    if (index !== -1) {
      notifications[index].status = element.status;
      const obj = { valid: true, value: element.target, id: element.id };
      if (!notifications[index].targets[0].value) {
        notifications[index].targets = [{ ...obj }];
      } else {
        notifications[index].targets.push(obj);
      }
    }
  });

  return {
    id,
    status,
    values: {
      name,
      cron,
      query,
    },
    notifications,
  };
};

const getNotificationIndex = (notifications, name) =>
  notifications.findIndex(({ type }) => type === name);

const reportDataReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case REPORT_FETCHED:
      const transformedReport = transformReportToState(payload);
      return {
        ...transformedReport,
        initReport: transformedReport,
      };
    case INIT_REPORT_VALUES_SET:
      return initState;
    case REPORT_VALUES_CHANGED: {
      const updatedState = update(state, {
        values: {
          [payload.name]: { $set: payload.newValue },
        },
      });

      return updatedState;
    }
    case NOTIFICATION_ELEMENT_ADD: {
      const index = getNotificationIndex(state.notifications, payload.name);
      const updatedState = update(state, {
        notifications: {
          [index]: {
            targets: { $push: [{ value: '', valid: false }] },
          },
        },
      });

      return updatedState;
    }
    case NOTIFICATION_ELEMENT_REMOVED: {
      const index = getNotificationIndex(state.notifications, payload.name);
      const updatedState = update(state, {
        notifications: {
          [index]: {
            targets: { $splice: [[payload.index, 1]] },
          },
        },
      });

      return updatedState;
    }
    case NOTIFICATION_VALUE_CHANGED: {
      const index = getNotificationIndex(state.notifications, payload.name);

      const updatedState = update(state, {
        notifications: {
          [index]: {
            targets: {
              [payload.index]: { $set: { value: payload.newValue, valid: payload.valid } },
            },
          },
        },
      });

      return updatedState;
    }
    case NOTIFICATION_ACTIVE_CHANGED: {
      const index = getNotificationIndex(state.notifications, payload.name);
      const currentStatus = state.notifications[index].status === ACTIVE ? INACTIVE : ACTIVE;
      const updatedState = update(state, {
        notifications: {
          [index]: {
            status: { $set: currentStatus },
          },
        },
      });

      return updatedState;
    }
    default:
      return state;
  }
};

export default reportDataReducer;
