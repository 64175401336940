import React, { useCallback, useMemo } from 'react';
import Router from 'next/router';
import get from 'lodash/get';

import authorizeService from '@api/services/admin/authorizeService';
import useToast from '@shared/hooks/useToast';
import RoutesConsts from '@shared/consts/RoutesConsts';
import ExceptionError from '@shared/components/ExceptionError';
import authHelper from '@shared/helpers/authHelper';

const HOME_ROUTES = RoutesConsts.HOME();

const useAuth = () => {
  const { actions: toastActions } = useToast();

  const verifyToken = useCallback(async token => {
    try {
      const result = await authorizeService.verifyToken(token);
      return get(result, 'verified');
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, []);

  const authorize = useCallback(
    async data => {
      try {
        const result = await authorizeService.authorize(data);
        authHelper.signIn(result.token);
        Router.push(HOME_ROUTES.HOME.path, HOME_ROUTES.HOME.as);
      } catch (e) {
        const customError = {
          ...e,
          ...(e.status === 401
            ? { description: 'The account you are trying to log in from is not authorized' }
            : { description: 'Login to the application has failed. Please try again.' }),
        };
        toastActions.addMessage({
          title: 'Error occured',
          text: <ExceptionError error={customError} />,
          type: 'danger',
        });
      }
    },
    [toastActions]
  );

  const authorizeHandler = useCallback(
    async err => {
      const customError = {
        ...err,
        message: 'Login to the application has failed. Please try again.',
      };
      toastActions.addMessage({
        title: 'Error occured',
        text: <ExceptionError error={customError} />,
        type: 'danger',
      });
    },
    [toastActions]
  );

  const actions = useMemo(
    () => ({
      verifyToken,
      authorize,
      authorizeHandler,
    }),
    [authorize, authorizeHandler, verifyToken]
  );

  return {
    actions,
  };
};

export default useAuth;
