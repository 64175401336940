import { UPDATE_PLACE } from '@redux/places/place.actions';

const place = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PLACE:
      return {
        [action.id]: {
          ...state,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default place;
