import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SidePanelContext = React.createContext();
SidePanelContext.displayName = 'SidePanelContext';

export const SidePanelContextProvider = ({ children }) => {
  const [sidePanel, setSidePanel] = useState(null);

  return (
    <SidePanelContext.Provider value={{ setSidePanel }}>
      {children}
      {sidePanel}
    </SidePanelContext.Provider>
  );
};

SidePanelContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default SidePanelContext;
