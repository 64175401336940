import { CURTAIN_CLOSE, CURTAIN_OPEN } from './curtain.actions';

const initState = {
  isOpen: false,
};

const curtainReducer = (state = initState, action) => {
  switch (action.type) {
    case CURTAIN_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    case CURTAIN_OPEN:
      return {
        ...state,
        isOpen: true,
      };
    default:
      return state;
  }
};

export default curtainReducer;
