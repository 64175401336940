import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { selectToasterState } from '@redux/toast/toast.selectors';
import { ADD_TOAST_MESSAGE, DELETE_TOAST_MESSAGE } from '@redux/toast/toast.actions';

const useToast = () => {
  const messages = useSelector(selectToasterState);
  const dispatch = useDispatch();

  const addMessage = useCallback(
    message => {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          ...message,
          id: moment().unix(),
        },
      });
    },
    [dispatch]
  );

  const deleteMessage = useCallback(
    id => {
      dispatch({
        type: DELETE_TOAST_MESSAGE,
        payload: id,
      });
    },
    [dispatch]
  );

  const actions = useMemo(
    () => ({
      addMessage,
      deleteMessage,
    }),
    [addMessage, deleteMessage]
  );

  return { messages, actions };
};

export default useToast;
