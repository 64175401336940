import AdminProvider from '@api/serviceProviders/AdminProvider';

class AuthorizeService extends AdminProvider {
  async authorize(data) {
    return this.api.post('', data);
  }

  async verifyToken(token) {
    return this.api.get(`token/${token}/verify`);
  }
}

const authorizeService = new AuthorizeService('/authorize');

export default authorizeService;
export { AuthorizeService };
