import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleLogin from 'react-google-login';

import config from '@root/config';

const GoogleLoginBox = styled.div`
  button {
    width: 100%;
    justify-content: center;
  }
`;

const SignInForm = ({ onGoogleSignIn, onGoogleSignInError }) => (
  <GoogleLoginBox>
    <GoogleLogin
      clientId={config.googleClientId}
      buttonText="Sign in with Google"
      onSuccess={onGoogleSignIn}
      onFailure={onGoogleSignInError}
      cookiePolicy="single_host_origin"
    />
  </GoogleLoginBox>
);

SignInForm.propTypes = {
  onGoogleSignIn: PropTypes.func,
  onGoogleSignInError: PropTypes.func,
};

SignInForm.defaultProps = {
  onGoogleSignIn() {},
  onGoogleSignInError() {},
};

export default SignInForm;
