import { combineReducers } from 'redux';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { SCRAPERS_FETCHED } from './scrapers.actions';

/* eslint-enable complexity */
const allIds = (state = [], action) => {
  switch (action.type) {
    case SCRAPERS_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const scrapersData = (state = {}, action) => {
  switch (action.type) {
    case SCRAPERS_FETCHED:
      return action.payload.entities.scrapers;
    default:
      return state;
  }
};

const scrapersReducer = combineReducers({
  byId: scrapersData,
  ids: allIds,
  metadata: createMetadataReducer('scrapers'),
});
// eslint-disable-next-line complexity

export default scrapersReducer;
