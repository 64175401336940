import { combineReducers } from 'redux';
import isNil from 'lodash/isNil';

import capitalizeFirstLetter from '@shared/utils/capitalizeFirstLetter';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { PLACE_DETAILS_STATUSES_FETCHED } from './placeDetailStatuses.actions';

const extendPlaceDetailsStatuses = placeDetailsStatuses => {
  if (isNil(placeDetailsStatuses)) return {};
  const placeDetailsStatusesKeys = Object.keys(placeDetailsStatuses);
  const extendedPlaceDetailsStatuses = placeDetailsStatusesKeys.reduce(
    (acc, placeDetailKey) => ({
      ...acc,
      [placeDetailKey]: [],
    }),
    {}
  );
  placeDetailsStatusesKeys.forEach(placeDetailStatus => {
    placeDetailsStatuses[placeDetailStatus].forEach(status => {
      extendedPlaceDetailsStatuses[placeDetailStatus].push({
        id: status,
        label: capitalizeFirstLetter(status),
      });
    });
  });
  return extendedPlaceDetailsStatuses;
};

const placeDetailsStatuses = (state = {}, action) => {
  switch (action.type) {
    case PLACE_DETAILS_STATUSES_FETCHED:
      return extendPlaceDetailsStatuses(action.payload);
    default:
      return state;
  }
};

const placeDetailStatusesReducer = combineReducers({
  statuses: placeDetailsStatuses,
  metadata: createMetadataReducer('placeDetailStatuses'),
});

export default placeDetailStatusesReducer;
