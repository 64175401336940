import React from 'react';
import PropTypes from 'prop-types';

import notifySentry from '@shared/utils/notifySentry';

import DefaultError from './DefaultError';

class DefaultErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      hasError: false,
    };
  }

  static async getInitialProps({ Component, ctx }) {
    try {
      let pageProps = {};

      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }

      return { pageProps };
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = notifySentry(error, ctx);
      return {
        hasError: true,
        errorEventId,
      };
    }
  }

  /* eslint-disable complexity */
  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }
  /* eslint-enable complexity */

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = notifySentry(error, { errorInfo });

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
  }

  render() {
    const ErrorComponent = this.props.errorComponent;
    return this.state.hasError ? (
      <ErrorComponent errorEventId={this.state.eventId} />
    ) : (
      this.props.children
    );
  }
}

DefaultErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  errorComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

DefaultErrorBoundary.defaultProps = {
  errorComponent: DefaultError,
};

export default DefaultErrorBoundary;
