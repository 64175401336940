import React from 'react';
import App from 'next/app';
import { ThemeProvider } from 'styled-components';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import theme from '@core/theme';
import DefaultError from '@shared/components/errors/DefaultError';
import DefaultErrorBoundary from '@shared/components/errors/DefaultErrorBoundary';
import GlobalStyles from '@core/theme/GlobalStyles';
import ChangePageLoader from '@shared/components/ChangePageLoader';
import { ModalContextProvider } from '@shared/contexts/ModalContext';
import { SidePanelContextProvider } from '@shared/contexts/SidePanelContext';

import '@core/theme/styles/scss/bootstrap/bootstrap.scss';
import 'nprogress/nprogress.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dates/lib/css/_datepicker.css';
import '@core/theme/styles/scss/style.scss';

class MyApp extends App {
  constructor(props) {
    super(props);
    this.state = {
      isWindowOnLoad: false,
      errorEventId: undefined,
      hasError: false,
    };
  }

  componentDidMount() {
    window.onload = () => {
      this.setState({
        isWindowOnLoad: true,
      });
    };
  }

  render() {
    const { Component, pageProps } = this.props;
    const { isWindowOnLoad } = this.state;
    if (!isWindowOnLoad) return null;
    return (
      <>
        <GlobalStyles />
        <DndProvider backend={Backend}>
          <ThemeProvider theme={theme}>
            <SidePanelContextProvider>
              <ModalContextProvider>
                <ChangePageLoader />
                <DefaultErrorBoundary errorComponent={DefaultError}>
                  <Component {...pageProps} />
                </DefaultErrorBoundary>
              </ModalContextProvider>
            </SidePanelContextProvider>
          </ThemeProvider>
        </DndProvider>
      </>
    );
  }
}

export default MyApp;
