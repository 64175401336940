export const FREIGHT_CAPACITIES_FETCHED = 'FREIGHT_CAPACITIES_FETCHED';
export const FREIGHT_CAPACITIES_CLEARED = 'FREIGHT_CAPACITIES_CLEARED';

export const FREIGHT_CAPACITY_CREATED = 'FREIGHT_CAPACITY_CREATED';
export const FREIGHT_CAPACITY_FETCHED = 'FREIGHT_CAPACITY_FETCHED';

export const FREIGHT_CAPACITY_CLAIMS_FETCHED = 'FREIGHT_CAPACITY_CLAIMS_FETCHED';
export const FREIGHT_CAPACITY_VIEWS_FETCHED = 'FREIGHT_CAPACITY_VIEWS_FETCHED';
export const FREIGHT_CAPACITY_IMPRESSIONS_FETCHED = 'FREIGHT_CAPACITY_IMPRESSIONS_FETCHED';
export const FREIGHT_CAPACITY_REJECTIONS_FETCHED = 'FREIGHT_CAPACITY_REJECTIONS_FETCHED';
