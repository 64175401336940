import drop from 'lodash/drop';

import { IS_FETCHING } from './fetchInfo.actions';

const initState = {
  fetchingArr: [],
};

const fetchInfoReducer = (state = initState, action) => {
  switch (action.type) {
    case IS_FETCHING: {
      const isFetchingArr = state.fetchingArr;

      if (action.isFetching) {
        return { ...state, fetchingArr: [...isFetchingArr, true] };
      }

      return { ...state, fetchingArr: drop(isFetchingArr, 1) };
    }
    default:
      return state;
  }
};

export default fetchInfoReducer;
