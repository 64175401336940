import { EXTEND_SCRAPER_LOG } from '@redux/scraperLogs/scraperLog.actions';

// eslint-disable-next-line complexity
const scraperLog = (state = {}, action) => {
  switch (action.type) {
    case EXTEND_SCRAPER_LOG: {
      const { children, ...rest } = action.payload;
      return {
        ...state,
        ...rest,
        isTaskError: rest.status === 'ERROR' || rest.event === 'ERROR',
        hasContentToShow: rest.meta || children ? rest.id : undefined,
      };
    }

    default:
      return state;
  }
};

export default scraperLog;
