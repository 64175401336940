import { UPDATE_FEEDBACK } from './feedback.one.actions';

const feedback = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FEEDBACK:
      return {
        [action.id]: {
          ...state,
          details: action.payload,
        },
      };

    default:
      return state;
  }
};

export default feedback;
