import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import isNil from 'lodash/isNil';

import { EXTEND_PLACE_META_TYPE } from '@redux/placeMetaTypes/placeMetaTypes.actions';
import capitalizeFirstLetter from '@shared/utils/capitalizeFirstLetter';

const getRidEmptyConstraintsValues = constraints => {
  if (isNil(constraints)) return [];
  return constraints.reduce((acc, constraint) => {
    if (constraint && constraint.place_meta_constraint_values.length > 0) acc.push(constraint);
    return acc;
  }, []);
};

const getPlaceMetaConstraintsTitle = (constraints, placeMetaTypes) => {
  if (isNil(constraints)) return [];
  const constraintsPlaceMetaIds = uniq(
    flatten(
      constraints.map(constraint =>
        constraint.place_meta_constraint_values.map(value => value.place_meta_type_id)
      )
    )
  );

  return constraintsPlaceMetaIds.map(
    id => placeMetaTypes && placeMetaTypes[id] && placeMetaTypes[id].name
  );
};

const getPlaceMetaTypeCustomType = placeMetaType => {
  if (placeMetaType.allow_multiple) return 'selectMany';
  if (placeMetaType.type === 'select') return 'selectOne';
  return placeMetaType.type;
};

const getPlaceMetaTypeLabel = placeMetaType => {
  if (placeMetaType.allow_multiple) return 'Select Many';
  if (placeMetaType.type === 'select') return 'Select One';
  return capitalizeFirstLetter(placeMetaType.type);
};

// eslint-disable-next-line complexity
const placeMetaTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case EXTEND_PLACE_META_TYPE: {
      const { payload, placeMetaTypes } = action;
      return {
        ...state,
        ...payload,
        place_meta_constraints: getRidEmptyConstraintsValues(payload.place_meta_constraints),
        placeMetaConstraintsTitle: getPlaceMetaConstraintsTitle(
          payload.place_meta_constraints,
          placeMetaTypes
        ),
        typeLabel: getPlaceMetaTypeLabel(payload),
        customType: getPlaceMetaTypeCustomType(payload),
      };
    }
    default:
      return state;
  }
};

export default placeMetaTypeReducer;
