import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DefaultButton from 'react-bootstrap/Button';
import isNil from 'lodash/isNil';

import Spinner from '@shared/components/Spinner';

const StyledButton = styled(DefaultButton)(
  ({ variant, $linkColorVariant, theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 2px;
  padding: 0.5rem 1.5rem;
  transition: 0.3s;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  ${variant === 'link' && `color: ${theme.colors[$linkColorVariant]}`};
  ${variant === 'outline-primary' &&
    `border: 1px solid ${theme.colors.mischka}; background: none;`};

  &:hover {
    backgroundColor: ${theme.colors[`${$linkColorVariant}Hover`]}
  };

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  svg {
    margin-right: 0.25rem;
    fill: ${theme.colors.white};
    ${variant === 'light' && `fill: ${theme.colors.white};`};
    ${variant === 'link' && `fill: ${theme.colors[$linkColorVariant]};`};
  }
`
);

const Button = ({ linkColorVariant, isLoading, children, visible, icon: Icon, ...rest }) => {
  if (!visible) return null;
  return (
    <StyledButton $linkColorVariant={linkColorVariant} {...rest}>
      {isLoading ? <Spinner /> : null} {!isNil(Icon) && <Icon />} {children}
    </StyledButton>
  );
};

Button.propTypes = {
  linkColorVariant: PropTypes.string,
  isLoading: PropTypes.bool,
  visible: PropTypes.bool,
  icon: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

Button.defaultProps = {
  linkColorVariant: 'primary',
  isLoading: false,
  icon: null,
  visible: true,
};

export default Button;
