import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';

NProgress.configure({ showSpinner: false });

const useChangeRoute = () => {
  const router = useRouter();

  const [isRouteChanging, setIsRouteChanging] = useState(false);

  useEffect(() => {
    const handleStart = url => {
      if (url !== router.pathname) setIsRouteChanging(true);
      NProgress.start();
    };
    const handleComplete = url => {
      if (url !== router.pathname) setIsRouteChanging(false);
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router.events, router.pathname]);

  return isRouteChanging;
};

export default useChangeRoute;
