import { combineReducers } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';

import filtersReducer from '@redux/shared/reducers/filters.reducer';
import createMetadataReducer from '@redux/shared/reducers/createMetadata.reducer';

import {
  SHIPMENT_REJECTION_TYPES_FETCHED,
  SHIPMENT_REJECTION_TYPE_ADDED,
  SHIPMENT_REJECTION_TYPE_UPDATE,
} from './shipmentRejectionTypes.actions';

const shipmentRejectionTypesIdsReducer = (state = [], action) => {
  switch (action.type) {
    case SHIPMENT_REJECTION_TYPES_FETCHED:
      return get(action, 'payload.result', []);
    case SHIPMENT_REJECTION_TYPE_ADDED:
      return [...state, get(action, 'payload.result')];
    default:
      return state;
  }
};

const shipmentRejectionTypesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIPMENT_REJECTION_TYPES_FETCHED:
      return get(action, 'payload.entities.shipmentRejectionType', {});
    case SHIPMENT_REJECTION_TYPE_ADDED:
    case SHIPMENT_REJECTION_TYPE_UPDATE:
      return merge({}, state, get(action, 'payload.entities.shipmentRejectionType'));
    default:
      return state;
  }
};

const shipmentRejectionTypesReducer = combineReducers({
  byId: shipmentRejectionTypesByIdReducer,
  ids: shipmentRejectionTypesIdsReducer,
  filters: filtersReducer('shipmentRejectionTypesFilters'),
  metadata: createMetadataReducer('shipmentRejectionTypesMetadata'),
});

export default shipmentRejectionTypesReducer;
