import { combineReducers } from 'redux';
import get from 'lodash/get';

import { DEVICES_FETCHED } from './devices.actions';

const devicesIdsReducer = (state = [], action) => {
  switch (action.type) {
    case DEVICES_FETCHED:
      return get(action, 'payload.result', []);
    default:
      return state;
  }
};

const devicesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case DEVICES_FETCHED:
      return get(action, 'payload.entities.device', {});
    default:
      return state;
  }
};

const devicesReducer = combineReducers({
  byId: devicesByIdReducer,
  ids: devicesIdsReducer,
});

export default devicesReducer;
