import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { EXTEND_TASK_AUDIT_LOG } from './auditLog.actions';
import { EXTEND_SCRAPER_LOG } from './scraperLog.actions';
import scraperLog from './scraperLog.reducer';

/* eslint-disable complexity */

const detailLogEventTypes = ['SAVE_PLACE', 'SAVE_DETAIL'];

const getSaveDetailEventForLog = children => {
  if (isNil(children)) return [];
  return children
    .filter(child => detailLogEventTypes.includes(child.event))
    .map(child => child.meta);
};

const isEmptyRun = node =>
  node.event === 'STOP' ||
  (node.event === 'START' &&
    ((node.children.length === 1 && node.children[0].event === 'STOP') ||
      (node.children.length === 2 &&
        node.children[0].event === 'START' &&
        node.children[1].event === 'STOP')));

const hasContentInside = children =>
  children.filter(child => child.hasEntries || !isEmptyRun(child)).length > 0;

const hasDetailsInside = children => children.length > 0;
const hasErrorsInside = child => !!(child.meta && child.meta.error);

const iterateThruChildren = collection => {
  if (isNil(collection)) return [];
  return (
    collection
      .filter(item => !detailLogEventTypes.includes(item.event))
      // eslint-disable-next-line no-use-before-define
      .map(item => auditLog({}, { type: EXTEND_TASK_AUDIT_LOG, payload: item }))
  );
};

const auditLog = (state = {}, action) => {
  switch (action.type) {
    case EXTEND_TASK_AUDIT_LOG: {
      if (!action.payload) return {};
      const { children, ...rest } = action.payload;

      const nestedChildren = iterateThruChildren(children);
      const details = getSaveDetailEventForLog(children);
      const isContentInside = hasContentInside(nestedChildren);
      const isDetailsInside = hasDetailsInside(details);
      const isErrorsInside = hasErrorsInside(rest);

      const logState = {
        ...state,
        status: rest.status || rest.event,
        hasEntries: isDetailsInside || isContentInside || isErrorsInside,
        isMetaParams: !isEmpty(rest.meta && rest.meta.params),
        isRestartAvailable: !!(
          rest.counts &&
          rest.counts.errors > 0 &&
          rest.event === 'START' &&
          rest.job
        ),
        typeLabel: 'Log',
        ...(nestedChildren ? { children: nestedChildren } : {}),
        ...(details ? { details } : {}),
      };
      return scraperLog(logState, {
        type: EXTEND_SCRAPER_LOG,
        payload: action.payload,
      });
    }
    default:
      return state;
  }
};

/* eslint-enable complexity */

export default auditLog;
