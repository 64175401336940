import { ADD_TOAST_MESSAGE, DELETE_TOAST_MESSAGE } from './toast.actions';

const trackRoutesReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_TOAST_MESSAGE:
      return [...state, action.payload];
    case DELETE_TOAST_MESSAGE:
      return state.filter(item => item.id !== action.payload);
    default:
      return state;
  }
};

export default trackRoutesReducer;
