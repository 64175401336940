import flattenDeep from 'lodash/flattenDeep';

import { EXTEND_PLACE_TYPE } from '@redux/placeTypes/placeTypes.actions';

const extendDefaultValuesByPlaceMetaId = (defaultValues, id) =>
  defaultValues.map(value => ({ ...value, place_meta_type_id: id }));

const getDefaultValues = placeType =>
  placeType.place_meta_types?.map(metaType =>
    extendDefaultValuesByPlaceMetaId(metaType.values, metaType.id)
  );

const placeTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case EXTEND_PLACE_TYPE: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        defaultValues: flattenDeep(getDefaultValues(payload)),
      };
    }
    default:
      return state;
  }
};

export default placeTypeReducer;
