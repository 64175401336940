import { combineReducers } from 'redux';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import some from 'lodash/some';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import {
  PLACE_DETAILS_FETCHED,
  PLACE_DETAIL_CREATE,
  PLACE_DETAIL_ADD_DATA_TO_UPDATE,
  PLACE_DETAIL_CLEAR_DATA_TO_UPDATE,
  PLACE_DETAIL_UPDATE,
  PLACE_DETAIL_REMOVE,
} from './placeDetails.actions';

/* eslint-disable complexity */
export const allIds = (state = [], action) => {
  switch (action.type) {
    case PLACE_DETAILS_FETCHED:
      return action.payload.result;
    case PLACE_DETAIL_CREATE:
      return [...state, action.payload.result];
    case PLACE_DETAIL_REMOVE:
      return state.filter(id => id !== action.payload);
    case PLACE_DETAIL_UPDATE:
      return [
        ...state.filter(item => item !== action.previousPlaceDetailId),
        action.payload.result,
      ];
    default:
      return state;
  }
};

export const placeDetailsData = (state = {}, action) => {
  switch (action.type) {
    case PLACE_DETAILS_FETCHED:
      return isNil(action.payload.entities.placeDetails)
        ? {}
        : action.payload.entities.placeDetails;
    case PLACE_DETAIL_UPDATE: {
      return {
        ...omitBy(state, { id: action.previousPlaceDetailId }),
        ...action.payload.entities.placeDetails,
      };
    }
    case PLACE_DETAIL_CREATE:
      return { ...state, ...action.payload.entities.placeDetails };
    case PLACE_DETAIL_REMOVE:
      return omitBy(state, { id: action.payload });
    default:
      return state;
  }
};

const toUpdate = (state = [], action) => {
  switch (action.type) {
    case PLACE_DETAIL_ADD_DATA_TO_UPDATE: {
      const isExistPlaceDetailValue = some(state, { id: action.payload.id });
      if (!isExistPlaceDetailValue) return [...state, action.payload];

      return state.map(item => (item.id === action.payload.id ? action.payload : item));
    }
    case PLACE_DETAIL_CLEAR_DATA_TO_UPDATE:
      return [];
    default:
      return state;
  }
};

/* eslint-enable complexity */

const placeDetailsReducer = combineReducers({
  byId: placeDetailsData,
  toUpdate,
  ids: allIds,
  metadata: createMetadataReducer('placeDetails'),
});

export default placeDetailsReducer;
