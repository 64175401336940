import { normalize } from 'normalizr';
import { combineReducers } from 'redux';
import isNil from 'lodash/isNil';

import { scrapersLogsSchema } from '@shared/schemas/scraperLogs';

import filtersReducer from '../shared/reducers/filters.reducer';
import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { EXTEND_TASK_LOG } from './taskLog.actions';
import taskLog from './taskLog.reducer';
import {
  TASK_LOGS_FETCHED,
  SCRAPER_TASK_LOAD_MORE,
  SCRAPER_LOGS_CLEAR,
  ADD_PENDING_RESTART_LOG,
  REMOVE_PENDING_RESTART_LOG,
  ADD_RESTARTED_TASK,
  SCRAPER_TASK_FETCHED,
} from './scraperLogs.actions';

const generateTaskLogs = logs => {
  if (isNil(logs)) return {};
  const newLogs = Object.keys(logs).map(logId =>
    taskLog({}, { type: EXTEND_TASK_LOG, payload: logs[logId] })
  );
  return normalize(newLogs, scrapersLogsSchema).entities.scraperLogs;
};

/* eslint-disable complexity */

const allIds = (state = [], action) => {
  switch (action.type) {
    case SCRAPER_TASK_FETCHED:
      return action.payload.result;
    case SCRAPER_TASK_LOAD_MORE:
      return [...state, ...action.payload.result];
    case SCRAPER_LOGS_CLEAR:
      return [];
    default:
      return state;
  }
};

const scraperLogsData = (state = {}, action) => {
  switch (action.type) {
    case SCRAPER_TASK_FETCHED:
      return generateTaskLogs(action.payload.entities.scraperLogs);
    case TASK_LOGS_FETCHED: {
      const currentTask = state[action.taskId];

      return {
        ...state,
        [action.taskId]: taskLog(
          {},
          {
            type: EXTEND_TASK_LOG,
            payload: {
              ...currentTask,
              children: [action.payload],
            },
          }
        ),
      };
    }
    case SCRAPER_TASK_LOAD_MORE:
      return {
        ...state,
        ...generateTaskLogs(action.payload.entities.scraperLogs),
      };
    case SCRAPER_LOGS_CLEAR:
      return {};
    default:
      return state;
  }
};

const pendingRestartLogIds = (state = [], action) => {
  switch (action.type) {
    case ADD_PENDING_RESTART_LOG:
      return [...state, action.taskId];
    case REMOVE_PENDING_RESTART_LOG:
      return state.filter(item => item !== action.taskId);
    default:
      return state;
  }
};

const restartedTasks = (state = [], action) => {
  switch (action.type) {
    case ADD_RESTARTED_TASK:
      return [...state, action.taskId];
    default:
      return state;
  }
};

// eslint-disable-next-line complexity

const scraperLogsReducer = combineReducers({
  pendingRestartLogIds,
  restartedTasks,
  byId: scraperLogsData,
  ids: allIds,
  filters: filtersReducer('scraperLogs'),
  metadata: createMetadataReducer('scraperLogs'),
});
export default scraperLogsReducer;
