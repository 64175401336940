import { combineReducers } from 'redux';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { TRACK_ROUTES_FETCHED } from './truckRoutes.actions';

const allIds = (state = [], action) => {
  switch (action.type) {
    case TRACK_ROUTES_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const usersData = (state = {}, action) => {
  switch (action.type) {
    case TRACK_ROUTES_FETCHED:
      return action.payload.entities.truckRoutes;
    default:
      return state;
  }
};

const trackRoutesReducer = combineReducers({
  byId: usersData,
  ids: allIds,
  metadata: createMetadataReducer('truckRoutes'),
});
export default trackRoutesReducer;
