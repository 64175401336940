import config from '@root/config';
import CoreApi from '@api/CoreApi';
import extendHeaderObjectWithBearerToken from '@api/utils/extendHeaderObjectWithBearerToken';

class AdminProvider extends CoreApi {
  constructor(endpoint) {
    super(config.apiUrl, endpoint);

    this.setInterceptors({
      beforeRequest: this.adminApiBeforeRequest,
    });
  }

  adminApiBeforeRequest(configObject) {
    return {
      ...configObject,
      headers: extendHeaderObjectWithBearerToken(configObject.headers),
    };
  }
}

export default AdminProvider;
