import config from '@root/config';
import authHelper from '@shared/helpers/authHelper';
import { getOrInitializeStore } from '@shared/utils/withRedux';
import { IS_FETCHING } from '@redux/fetchInfo/fetchInfo.actions';

import ApiError from './models/ApiError';
import axiosProvider from './axiosProvider';

const isRequestAllowedToDispatch = baseUrl => !baseUrl.includes(config.bingApiUrl);

const startFetching = baseUrl => {
  if (isRequestAllowedToDispatch(baseUrl)) {
    getOrInitializeStore().dispatch({
      type: IS_FETCHING,
      isFetching: true,
    });
  }
};

const endFetching = baseUrl => {
  if (isRequestAllowedToDispatch(baseUrl)) {
    getOrInitializeStore().dispatch({
      type: IS_FETCHING,
      isFetching: false,
    });
  }
};

class CoreApi {
  constructor(baseUrl, endpoint = '') {
    this.api = axiosProvider(baseUrl);
    this.baseUrl = baseUrl;
    this.setInterceptors = this.setInterceptors.bind(this);
    this.setInterceptors({
      beforeRequest: this._beforeRequest,
      requestError: this._requestError,
      afterResponse: this._afterResponse,
      responseError: this._responseError,
    });
    this.setEndpointUrl(endpoint);
  }

  setInterceptors({ beforeRequest, requestError, afterResponse, responseError }) {
    this.api.interceptors.request.use(beforeRequest, requestError);
    this.api.interceptors.response.use(afterResponse, responseError);
  }

  setEndpointUrl(path) {
    this.api.defaults.baseURL = `${this.baseUrl}${path}`;
  }

  _beforeRequest(configObject) {
    startFetching(configObject.baseURL);
    return configObject;
  }

  _requestError(error) {
    throw error;
  }

  _afterResponse(resp) {
    endFetching(resp.config.baseURL);
    return resp.data || resp;
  }

  _responseError(error) {
    endFetching(error.config.baseURL);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          authHelper.signOut();
          break;
        default:
          throw new ApiError(error);
      }
    }

    throw new ApiError(error);
  }
}

export default CoreApi;
