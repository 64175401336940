import { combineReducers } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';

import createMetadataReducer from '@redux/shared/reducers/createMetadata.reducer';
import filtersReducer from '@redux/shared/reducers/filters.reducer';

import {
  CAPACITY_TYPES_FETCHED,
  CAPACITY_TYPE_ADDED,
  CAPACITY_TYPE_UPDATE,
} from './capacityTypes.actions';

const capacityTypesIdsReducer = (state = [], action) => {
  switch (action.type) {
    case CAPACITY_TYPES_FETCHED:
      return get(action, 'payload.result', []);
    case CAPACITY_TYPE_ADDED:
      return [...state, get(action, 'payload.result')];
    default:
      return state;
  }
};

const capacityTypesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case CAPACITY_TYPES_FETCHED:
      return get(action, 'payload.entities.capacityType', {});
    case CAPACITY_TYPE_ADDED:
    case CAPACITY_TYPE_UPDATE:
      return merge({}, state, get(action, 'payload.entities.capacityType'));
    default:
      return state;
  }
};

const capacityTypesReducer = combineReducers({
  byId: capacityTypesByIdReducer,
  ids: capacityTypesIdsReducer,
  filters: filtersReducer('capacityTypesFilters'),
  metadata: createMetadataReducer('capacityTypesMetadata'),
});

export default capacityTypesReducer;
