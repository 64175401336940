import { combineReducers } from 'redux';
import get from 'lodash/get';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';
import filtersReducer from '../shared/reducers/filters.reducer';

import reviewsCount from './reviewsCount.reducer';
import { REVIEWS_FETCHED, REVIEW_FETCHED } from './reviews.actions';

const allIds = (state = [], action) => {
  switch (action.type) {
    case REVIEWS_FETCHED:
    case REVIEW_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const placeReviewsData = (state = {}, action) => {
  switch (action.type) {
    case REVIEWS_FETCHED:
    case REVIEW_FETCHED:
      return get(action, 'payload.entities.reviews', {});
    default:
      return state;
  }
};

const reviewsReducer = combineReducers({
  byId: placeReviewsData,
  ids: allIds,
  reviewsCount,
  filters: filtersReducer('reviewFilters', {}),
  metadata: createMetadataReducer('reviews'),
});

export default reviewsReducer;
