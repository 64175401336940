const getTheme = shared => ({
  colors: {
    // Base Colors
    primary: shared.colors.primary,
    secondary: shared.colors.secondary,
    black: shared.colors.firefly,
    lightGray: shared.colors.gray200,
    ultraLightGray: shared.colors.gray100,
    customGray: '#F7F7F7',
    white: shared.colors.white,
    muted: shared.colors.gray500,
    // Informative Colors
    success: shared.colors.success,
    warning: shared.colors.warning,
    danger: shared.colors.danger,
    lightDanger: shared.colors.lightDanger,
    info: shared.colors.info,
    // Per Functionality/Element
    border: {
      default: shared.colors.gray200,
    },
    integrationMap: {
      cluster: {
        default: shared.colors.mountainMeadow,
        excluded: shared.colors.redOrange,
      },
    },
    label: {
      text: {
        success: shared.colors.success,
        warning: shared.colors.warning,
        primary: shared.colors.primary,
      },
      background: {
        success: shared.colors.lightSuccess,
        warning: shared.colors.lightWarning,
        primary: shared.colors.primaryLight,
      },
    },
    link: {
      default: shared.colors.azureRadiance,
      hover: shared.colors.darkAzureRadiance,
    },
    text: {
      default: shared.colors.firefly,
      light: shared.colors.grayChateau,
      hover: shared.colors.darkFirefly,
      active: shared.colors.darkerFirefly,
    },
    table: {
      header: {
        text: {
          default: shared.colors.grayChateau,
        },
        background: {
          default: shared.colors.white,
          secondary: shared.colors.whiteLilac,
        },
      },
      row: {
        border: {
          default: shared.colors.athensGray,
        },
      },
    },
    input: {
      text: {
        placeholder: shared.colors.grayChateau,
      },
      border: {
        default: shared.colors.gray400,
        focus: shared.colors.azureRadiance,
      },
      background: {
        primary: shared.colors.white,
        secondary: shared.colors.whiteLilac,
      },
    },
    button: {
      default: shared.colors.azureRadiance,
      hover: shared.colors.darkAzureRadiance,
      active: shared.colors.darkenAzureRadiance,
    },
    richEditor: {
      toolbar: {
        background: {
          primary: shared.colors.whiteLilac,
        },
      },
    },
    sidebar: {
      background: shared.colors.firefly,
      sideItem: {
        text: {
          default: shared.colors.grayChateau,
          active: shared.colors.white,
          disabled: shared.colors.oxfordBlue,
          hover: shared.colors.mischka,
        },
        background: {
          active: shared.colors.firefly,
        },
        border: {
          default: shared.colors.oxfordBlue,
          active: shared.colors.stateGray,
        },
      },
    },
    switch: {
      bulletShadow: '#0a1224',
    },
    adminLayout: {
      content: {
        background: shared.colors.gray100,
      },
    },
    accordion: {
      background: '#FFFFFF',
      border: shared.colors.gray200,
      text: '#333D54',
    },
    autoComplete: {
      input: {
        border: shared.colors.gray200,
        text: shared.colors.gray600,
      },
      icon: shared.colors.gray600,
    },
    spinner: {
      border: {
        light: shared.colors.gray200,
        dark: shared.colors.gray800,
      },
    },
    dangerZone: {
      background: '#F5C6CB',
    },
    databaseRecordContainer: {
      background: '#F7F7F7',
    },
    feedbackRow: {
      resolvedBackground: shared.colors.gray100,
      hoverBackground: shared.colors.gray100,
    },
    placeDetailsEdit: {
      background: '#F7F7F7',
      metaTypeName: {
        color: shared.colors.gray600,
      },
      lastChange: {
        color: shared.colors.gray400,
      },
    },
    scrapperCard: {
      box: {
        job: {
          icon: shared.colors.white,
        },
      },
    },
    detailHistoryModal: {
      status: {
        published: shared.colors.success,
        deleted: shared.colors.danger,
        conflict: shared.colors.danger,
      },
    },
    scraperDetailCard: {
      color: shared.colors.gray600,
      background: {
        ERROR: shared.colors.lightDanger,
        REJECTED: shared.colors.lightDanger,
        STOP: shared.colors.white,
        NEW: shared.colors.white,
        START: shared.colors.white,
        IN_PROGRESS: shared.colors.gray100,
        DONE: shared.colors.gray100,
        SAVE_PLACE: shared.colors.gray100,
        SAVE_DETAIL: shared.colors.gray100,
        WARNING: shared.colors.warning,
        INFO: shared.colors.info,
      },
      subline: {
        color: shared.colors.gray700,
      },
    },
    ...shared.colors,
  },
});

export default getTheme;
