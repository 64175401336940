import { combineReducers } from 'redux';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';
import selectedIdsReducer from '../shared/reducers/selectedIds.reducer';

import { massEditData, massEditDataIds, locationName } from './massEditData.reducer';
import stepsMetadata from './stepsMetaData.reducer';
import massEditChangeDetails from './massEditChangeDetails.reducer';
import massEditInitPlacesToUpdate from './massEditInitPlacesToUpdate.reducer';

const massEditReducer = combineReducers({
  stepsMetadata,
  massEditData,
  massEditDataIds,
  locationName,
  massEditChangeDetails,
  massEditInitPlacesToUpdate,
  selectedPlacesIds: selectedIdsReducer('massEditPlaceIds'),
  selectedChangeDetailPlaceIds: selectedIdsReducer('massEditChangeDetailPlaceIds'),
  metadata: createMetadataReducer('massEdit'),
});

export default massEditReducer;
