import { combineReducers } from 'redux';
import get from 'lodash/get';

import selectedIdsReducer from '../shared/reducers/selectedIds.reducer';
import filtersReducer from '../shared/reducers/filters.reducer';
import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import {
  ANNOTATIONS_FETCHED,
  ANNOTATIONS_UPDATE_PENDING_COUNT,
  ANNOTATIONS_UPDATE_PENDING_COUNT_BY_REVIEW,
} from './annotations.actions';
import { PLACE_ANNOTATION_FETCHED } from './placeAnnotations.actions';

const annotationIds = (state = [], action) => {
  switch (action.type) {
    case ANNOTATIONS_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const placeAnnotationIds = (state = [], action) => {
  switch (action.type) {
    case PLACE_ANNOTATION_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const annotationData = (state = {}, action) => {
  switch (action.type) {
    case ANNOTATIONS_FETCHED:
      return get(action, 'payload.entities.annotations', {});
    default:
      return state;
  }
};

const placeAnnotationsData = (state = {}, action) => {
  switch (action.type) {
    case PLACE_ANNOTATION_FETCHED:
      return get(action, 'payload.entities.placeAnnotations', {});
    default:
      return state;
  }
};

const initAnnotationsCountState = {
  pending: 0,
  pendingByReview: 0,
};
const annotationsCountReducer = (state = initAnnotationsCountState, action) => {
  switch (action.type) {
    case ANNOTATIONS_UPDATE_PENDING_COUNT:
      return {
        ...state,
        pending: action.payload,
      };
    case ANNOTATIONS_UPDATE_PENDING_COUNT_BY_REVIEW:
      return {
        ...state,
        pendingByReview: action.payload,
      };
    default:
      return state;
  }
};

const annotationsReducer = combineReducers({
  annotationById: annotationData,
  placeAnnotationById: placeAnnotationsData,
  annotationIds,
  placeAnnotationIds,
  annotationsFilters: filtersReducer('annotations', {
    statuses: ['pending'],
  }),
  placeAnnotationsFilters: filtersReducer('placeAnnotations', {
    statuses: ['published'],
  }),
  annotationsMetadata: createMetadataReducer('annotations'),
  placeAnnotationsMetadata: createMetadataReducer('placeAnnotations'),
  selectedAnnotationIds: selectedIdsReducer('annotations'),
  selectedPlaceAnnotationIds: selectedIdsReducer('placeAnnotations'),
  annotationsCount: annotationsCountReducer,
});

export default annotationsReducer;
