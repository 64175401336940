export const FREIGHT_SHIPMENTS_FETCHED = 'FREIGHT_SHIPMENTS_FETCHED';
export const FREIGHT_SHIPMENTS_CLEARED = 'FREIGHT_SHIPMENTS_CLEARED';
export const FREIGHT_SHIPMENT_ADDED = 'FREIGHT_SHIPMENT_ADDED';
export const FREIGHT_SHIPMENT_UPDATED = 'FREIGHT_SHIPMENT_UPDATED';
export const FREIGHT_SHIPMENT_EQUIPMENT_ADDED = 'FREIGHT_SHIPMENT_EQUIPMENT_ADDED';
export const FREIGHT_SHIPMENT_FETCHED = 'FREIGHT_SHIPMENT_FETCHED';

export const FREIGHT_SHIPMENT_IMPRESSIONS_FETCHED = 'FREIGHT_SHIPMENT_IMPRESSIONS_FETCHED';
export const FREIGHT_SHIPMENT_REJECTIONS_FETCHED = 'FREIGHT_SHIPMENT_REJECTIONS_FETCHED';
export const FREIGHT_SHIPMENT_CLAIMS_FETCHED = 'FREIGHT_SHIPMENT_CLAIMS_FETCHED';
export const FREIGHT_SHIPMENT_VIEWS_FETCHED = 'FREIGHT_SHIPMENT_VIEWS_FETCHED';
