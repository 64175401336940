import { combineReducers } from 'redux';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { USERS_FETCHED } from './users.actions';
import { USER_FETCHED } from './user.actions';

const allIds = (state = [], action) => {
  switch (action.type) {
    case USERS_FETCHED:
    case USER_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const usersData = (state = {}, action) => {
  switch (action.type) {
    case USERS_FETCHED:
    case USER_FETCHED:
      return action.payload.entities.users;
    default:
      return state;
  }
};

const usersReducer = combineReducers({
  byId: usersData,
  ids: allIds,
  metadata: createMetadataReducer('users'),
});

export default usersReducer;
