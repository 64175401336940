import getConfig from 'next/config';

const defaultConfig = {
  API_URL: null,
  SCRAPERS_API_URL: null,
  BING_API_URL: null,
  BING_APP_ID: null,
  ALGOLIA_APLICATION_ID: null,
  ALGOLIA_API_KEY: null,
  SENTRY_DSN: null,
  STAGE: null,
  SENTRY_RELEASE: null,
  GOOGLE_CLIENT_ID: null,
  GOOGLE_SITE_VERIFICATION_ID: null,
  MAPBOX_ACCESS_TOKEN: null,
  MAPBOX_STYLE_URL: null,
  MAPBOX_API_URL: null,
  TIMEZONE_TRUCKMAP_API_URL: null,
};

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig() || {
  publicRuntimeConfig: {
    ...defaultConfig,
  },
  serverRuntimeConfig: {
    ...defaultConfig,
  },
};

const getApiUrl = () => {
  const url = serverRuntimeConfig.API_URL || publicRuntimeConfig.API_URL;

  if (url) {
    return `${url}/v1`;
  }
  return null;
};

const config = {
  pagination: {
    defaultPageSize: 25,
    pageSizes: [10, 25, 50, 100],
  },
  initialPaginationFilters: {
    limit: 25,
    offset: 0,
  },
  apiUrl: getApiUrl() || 'http://localhost:3001/v1',
  // : 'https://dev-admin-api-honest-polecat.herokuapp.com/v1',
  scrapersApiUrl: publicRuntimeConfig.SCRAPERS_API_URL
    ? `${publicRuntimeConfig.SCRAPERS_API_URL}/v1`
    : 'http://localhost:3002/v1',
  bingApiUrl: publicRuntimeConfig.BING_API_URL || 'https://www.bing.com/api/v6',
  bingApiId: publicRuntimeConfig.BING_APP_ID || 'D41D8CD98F00B204E9800998ECF8427E1FBE79C2',
  algoliaApplicationID: publicRuntimeConfig.ALGOLIA_APLICATION_ID || 'AFD8U65566',
  algoliaAPIKey: publicRuntimeConfig.ALGOLIA_API_KEY || 'e054fc800304ae97d26fdb33feb4537d',
  sentryDsn:
    publicRuntimeConfig.SENTRY_DSN || 'https://ada342a949d44b08bf40e59bf90bb185@sentry.io/1885696',
  sentryRelease: publicRuntimeConfig.SENTRY_RELEASE,
  stage: publicRuntimeConfig.STAGE || 'local',
  mapboxApiUrl: publicRuntimeConfig.MAPBOX_API_URL || 'https://api.mapbox.com/geocoding/v5/mapbox',
  mapboxAccessToken:
    publicRuntimeConfig.MAPBOX_ACCESS_TOKEN ||
    'pk.eyJ1IjoidHJ1Y2ttYXAiLCJhIjoiY2tpZXU0cGxtMDNkMDJ5cjc1eDZsZDE1bSJ9.9PRKNblbpjEK6BObxv7s6w',
  mapBoxStyleUrl: publicRuntimeConfig.MAPBOX_STYLE_URL || 'mapbox://styles/mapbox',
  googleSiteVerificationId:
    publicRuntimeConfig.GOOGLE_SITE_VERIFICATION_ID ||
    'UUTDIRJvalIKKSnY9xLidDiBsVNPHFYiATViZsmuqPk',
  googleClientId:
    publicRuntimeConfig.GOOGLE_CLIENT_ID ||
    '252450349376-8a5caar2hhei41d6j9bsoidsn6vriiom.apps.googleusercontent.com',
  timezoneTruckmapApiUrl:
    publicRuntimeConfig.TIMEZONE_TRUCKMAP_API_URL || 'https://timezone.truckmap.com',
};

export default config;
