import * as Sentry from '@sentry/browser';

import config from '@root/config';

if (config.stage !== 'local') {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.stage,
    release: config.sentryRelease,
  });
}
/* eslint-disable complexity */
const notifySentry = (err, ctx) => {
  Sentry.configureScope(scope => {
    if (err.statusCode) {
      scope.setExtra('statusCode', err.statusCode);
    }

    if (ctx) {
      const { req, res, errorInfo, query, pathname } = ctx;

      if (res && res.statusCode) {
        scope.setExtra('statusCode', res.statusCode);
      }

      if (typeof window !== 'undefined') {
        scope.setTag('ssr', false);
        scope.setExtra('query', query);
        scope.setExtra('pathname', pathname);
      } else {
        scope.setTag('ssr', true);
        scope.setExtra('url', req.url);
        scope.setExtra('method', req.method);
        scope.setExtra('headers', req.headers);
        scope.setExtra('params', req.params);
        scope.setExtra('query', req.query);

        // On server-side we take session cookie directly from request
        if (req.cookies.sid) {
          scope.setUser({ id: req.cookies.sid });
        }
      }

      if (errorInfo) {
        Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]));
      }
    }
  });

  Sentry.captureException(err);
};
/* eslint-enable complexity */
export default notifySentry;
