import authHelper from '@shared/helpers/authHelper';

const extendHeaderObjectWithBearerToken = headers => {
  const token = authHelper.getToken();
  const bearer = token ? { Authorization: `Bearer ${token}` } : {};
  return {
    ...headers,
    ...bearer,
  };
};

export default extendHeaderObjectWithBearerToken;
