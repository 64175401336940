import { combineReducers } from 'redux';
import get from 'lodash/get';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import { SCRAPER_CRONS_FETCHED, SCRAPER_CRON_REMOVE } from './scraperCrons.actions';

const allIds = (state = [], action) => {
  switch (action.type) {
    case SCRAPER_CRONS_FETCHED:
      return action.payload.result;
    case SCRAPER_CRON_REMOVE:
      return state.filter(id => id !== action.id);
    default:
      return state;
  }
};

const scraperCronsData = (state = {}, action) => {
  switch (action.type) {
    case SCRAPER_CRONS_FETCHED:
      return get(action, 'payload.entities.scraperCrons', {});
    case SCRAPER_CRON_REMOVE: {
      const newState = { ...state };
      delete newState[action.id];
      return newState;
    }
    default:
      return state;
  }
};

const scraperCronsReducer = combineReducers({
  byId: scraperCronsData,
  ids: allIds,
  metadata: createMetadataReducer('scraperCrons'),
});

export default scraperCronsReducer;
