import React from 'react';
import PropTypes from 'prop-types';
import Toast from 'react-bootstrap/Toast';
import styled from 'styled-components';
import uniqid from 'uniqid';

import useToast from '@shared/hooks/useToast';
import { DEFAULT_DELAY, DANGER_STATUS_DEFAULT_DELAY } from '@shared/consts/toasterConts';

const Container = styled.div`
  position: fixed;
  z-index: 9999;
  left: 2rem;
  bottom: 2rem;
  top: 2rem;
  overflow: auto;
  height: fit-content;
`;

const StyledToast = styled(Toast)`
  border: 1px solid ${props => props.theme.colors[props.type]};
`;

const ToastTitle = styled.span`
  color: ${props => props.theme.colors[props.type]};
  font-weight: bold;
  margin-right: auto;
`;

const getDelay = type => {
  const delay = new Map([
    ['danger', () => DANGER_STATUS_DEFAULT_DELAY],
    ['default', () => DEFAULT_DELAY],
  ]);

  const action = delay.get(type) || delay.get('default');

  return action.call(this);
};

const CustomToast = ({ message, onClose }) => (
  <StyledToast
    onClose={() => {
      onClose(message.id);
    }}
    show
    type={message.type}
    autohide
    delay={message.delay || getDelay(message.type)}
  >
    <Toast.Header>
      <ToastTitle type={message.type}>{message.title}</ToastTitle>
    </Toast.Header>
    <Toast.Body>{message.text}</Toast.Body>
  </StyledToast>
);

CustomToast.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    title: PropTypes.string,
    type: PropTypes.string,
    delay: PropTypes.number,
  }),
};

CustomToast.defaultProps = {
  message: {
    id: '',
    text: '',
    title: '',
    type: 'primary',
    delay: undefined,
  },
};

const Toaster = () => {
  const { messages, actions } = useToast();

  return (
    <Container>
      {messages.map(message => (
        <CustomToast key={uniqid()} message={message} onClose={actions.deleteMessage} />
      ))}
    </Container>
  );
};

export default Toaster;
