import { combineReducers } from 'redux';

import freightRequestsReducer from '@modules/freightRequests/reducer';
import freightCapacitiesReducer from '@modules/freightCapacities/reducer';
import freightIntegrationsReducer from '@modules/freightIntegrations/reducer';
import freightAnalyticsReducer from '@modules/freightAnalytics/reducer';

import scrapersReducer from './scrapers/scrapers.reducer';
import curtainReducer from './curtain/curtain.reducer';
import usersReducer from './users/users.reducer';
import companiesReducer from './companies/companies.reducer';
import feedbackReducer from './feedback/feedback.many.reducer';
import scraperLogsReducer from './scraperLogs/scraperLogs.reducer';
import placesReducer from './places/places.reducer';
import placeTypesReducer from './placeTypes/placeTypes.reducer';
import truckRoutesReducer from './truckRoutes/truckRoutes.reducer';
import placeDetailsReducer from './placeDetails/placeDetails.reducer';
import historyPlaceDetailsReducer from './historyPlaceDetails/historyPlaceDetails.reducer';
import placeMetaTypesReducer from './placeMetaTypes/placeMetaTypes.reducer';
import scraperCronsReducer from './scraperCrons/scraperCrons.reducer';
import toastReducer from './toast/toast.reducer';
import fetchInfoReducer from './fetchInfo/fetchInfo.reducer';
import massEditReducer from './massEdit/massEdit.reducer';
import dictionaryReducer from './dictionary/dictionary.reducer';
import annotationTypesReducer from './annotationTypes/annotationTypes.reducer';
import annotationsReducer from './annotations/annotations.reducer';
import placeDetailStatusesReducer from './placeDetailStatuses/placeDetailStatuses.reducer';
import reviewsReducer from './reviews/reviews.reducer';
import reportsReducer from './reports/reports.reducer';
import reviewSectionTypesReducer from './reviewSectionTypes/reviewSectionTypes.reducer';
import reviewItemTypesReducer from './reviewItemTypes/reviewItemTypes.reducer';
import reviewFeedbackReducer from './reviewFeedback/reviewFeedback.reducer';
import partnersReducer from './partners/partners.reducer';
import shipmentRejectionTypesReducer from './shipmentRejectionTypes/shipmentRejectionTypes.reducer';
import freightDisplayTypesReducer from './freightDisplayTypes/freightDisplayTypes.reducer';
import capacityTypesReducer from './capacityTypes/capacityTypes.reducer';
import equipmentTypesReducer from './equipmentTypes/equipmentTypes.reducer';
import freightShipmentsReducer from './freightShipments/freightShipments.reducer';
import devicesReducer from './devices/devices.reducer';

const rootReducer = () =>
  combineReducers({
    scrapers: scrapersReducer,
    users: usersReducer,
    companies: companiesReducer,
    feedback: feedbackReducer,
    curtain: curtainReducer,
    places: placesReducer,
    placeDetails: placeDetailsReducer,
    truckRoutes: truckRoutesReducer,
    scraperLogs: scraperLogsReducer,
    placeTypes: placeTypesReducer,
    toaster: toastReducer,
    dictionary: dictionaryReducer,
    placeMetaTypes: placeMetaTypesReducer,
    annotations: annotationsReducer,
    scraperCrons: scraperCronsReducer,
    fetchInfo: fetchInfoReducer,
    annotationTypes: annotationTypesReducer,
    massEdit: massEditReducer,
    placeDetailStatuses: placeDetailStatusesReducer,
    historyPlaceDetails: historyPlaceDetailsReducer,
    reviews: reviewsReducer,
    reports: reportsReducer,
    reviewSectionTypes: reviewSectionTypesReducer,
    reviewItemTypes: reviewItemTypesReducer,
    reviewFeedback: reviewFeedbackReducer,
    partners: partnersReducer,
    freightIntegrations: freightIntegrationsReducer,
    shipmentRejectionTypes: shipmentRejectionTypesReducer,
    freightDisplayTypes: freightDisplayTypesReducer,
    capacityTypes: capacityTypesReducer,
    equipmentTypes: equipmentTypesReducer,
    freightShipments: freightShipmentsReducer,
    freightRequests: freightRequestsReducer,
    devices: devicesReducer,
    freightCapacities: freightCapacitiesReducer,
    freightAnalytics: freightAnalyticsReducer,
  });

export default rootReducer;
