import { combineReducers } from 'redux';

import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import {
  HISTORY_PLACE_DETAILS_FETCHED,
  HISTORY_PLACE_DETAILS_UPDATE,
} from './historyPlaceDetails.actions';

/* eslint-disable complexity */
const allIds = (state = [], action) => {
  switch (action.type) {
    case HISTORY_PLACE_DETAILS_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const historyPlaceDetailsData = (state = {}, action) => {
  switch (action.type) {
    case HISTORY_PLACE_DETAILS_FETCHED:
      return action.payload.entities.historyPlaceDetails;
    case HISTORY_PLACE_DETAILS_UPDATE:
      return {
        ...state,
        ...action.payload.entities.historyPlaceDetails,
      };
    default:
      return state;
  }
};

/* eslint-enable complexity */

const historyPlaceDetailsReducer = combineReducers({
  byId: historyPlaceDetailsData,
  ids: allIds,
  metadata: createMetadataReducer('historyPlaceDetails'),
});

export default historyPlaceDetailsReducer;
