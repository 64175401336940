import styled from 'styled-components';
import JSONPretty from 'react-json-pretty';

const JsonFormatter = styled(JSONPretty)`
  .__json-key__ {
    color: ${props => props.theme.colors.text.default};
  }

  .__json-string__ {
    color: ${props => props.theme.colors.success};
  }

  .__json-value__ {
    color: ${props => props.theme.colors.primary};
  }

  .__json-boolean__ {
    color: ${props => props.theme.colors.danger};
  }
`;

export default JsonFormatter;
