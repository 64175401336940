import {
  NEXT_STEP,
  PREVIOUS_STEP,
  UPDATED_PLACE_FILTERS,
  CLEAR_MASS_EDIT_STATE,
} from './massEdit.actions';

const initState = {
  currentStep: 1,
  placesFilter: {
    placeTypeId: '',
    maxDistance: 5,
    lon: '',
    lat: '',
    ids: [],
    filteringOption: 'location',
  },
};
/* eslint-disable complexity */
const stepsMetadata = (state = initState, action) => {
  switch (action.type) {
    case NEXT_STEP: {
      return {
        ...state,
        currentStep: action.currentStep,
      };
    }
    case PREVIOUS_STEP: {
      return {
        ...state,
        currentStep: action.currentStep,
      };
    }
    case UPDATED_PLACE_FILTERS: {
      return {
        ...state,
        placesFilter: action.placesFilter,
      };
    }
    case CLEAR_MASS_EDIT_STATE:
      return initState;
    default:
      return state;
  }
};
/* eslint-enable complexity */
export default stepsMetadata;
