import { combineReducers } from 'redux';
import get from 'lodash/get';

import filtersReducer from '../shared/reducers/filters.reducer';
import createMetadataReducer from '../shared/reducers/createMetadata.reducer';

import {
  REVIEW_FEEDBACK_MANY_FETCHED,
  REVIEW_FEEDBACK_ONE_FETCHED,
  REVIEW_FEEDBACK_UPDATE_NOT_VERIFIED_COUNT,
} from './reviewFeedback.actions';

const allIds = (state = [], action) => {
  switch (action.type) {
    case REVIEW_FEEDBACK_MANY_FETCHED:
    case REVIEW_FEEDBACK_ONE_FETCHED:
      return action.payload.result;
    default:
      return state;
  }
};

const itemTypesData = (state = {}, action) => {
  switch (action.type) {
    case REVIEW_FEEDBACK_MANY_FETCHED:
    case REVIEW_FEEDBACK_ONE_FETCHED:
      return get(action, 'payload.entities.reviewFeedback', {});
    default:
      return state;
  }
};

const initCountsState = {
  notVerifiedCount: 0,
};

const countsReducer = (state = initCountsState, action) => {
  switch (action.type) {
    case REVIEW_FEEDBACK_UPDATE_NOT_VERIFIED_COUNT:
      return {
        ...state,
        notVerifiedCount: action.totalCount,
      };
    default:
      return state;
  }
};

const reviewFeedbackReducer = combineReducers({
  byId: itemTypesData,
  ids: allIds,
  filters: filtersReducer('reviewFeedback'),
  counts: countsReducer,
  metadata: createMetadataReducer('reviewFeedback'),
});

export default reviewFeedbackReducer;
